import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addFamilyReg, addFamilyRegImage, removeFamilyRegImage } from '../../services/familyRegServices';
import { setNotification } from '../../components/notification/ToastNotification';
import { EMAIL_ALREADY_EXIST, MOBILE_ALREADY_EXIST } from '../../config/permissions';
import { message } from '../../utilities/locals/messages';

export interface FamilyState {
    loading: 'idle' | 'loading' | 'success' | 'error' | 'updatedSuccess';
    uploadedImg: string;
    imageId: number | null;
    contactEmail: string;
    contactPhone: string; 
}

const initialState: FamilyState = {
    loading: 'idle',
    uploadedImg: '',
    imageId: null,
    contactEmail: '', 
    contactPhone: '' 
}

export const familyRegistrationSlice = createSlice({
    name: 'familyRegistrationSlice',
    initialState,
    reducers: {
        changeLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        resetImage: (state) => {
            state.imageId = null;
            state.uploadedImg = '';
        },
        resetContactDetails: (state) => {
            state.loading = 'idle';
            state.contactEmail = '';
            state.contactPhone = '';
        },
    },
    extraReducers: (builder) => {

        builder.addCase(addFamilyRegImage.pending, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addFamilyRegImage.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.profile_pic;
            state.imageId = action.payload.id;
            state.loading = 'updatedSuccess';
        });
        builder.addCase(addFamilyRegImage.rejected, (state) => {
            state.loading = 'error';
        });



        builder.addCase(removeFamilyRegImage.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeFamilyRegImage.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'updatedSuccess';
        });
        builder.addCase(removeFamilyRegImage.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(addFamilyReg.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addFamilyReg.fulfilled, (state, action) => {
            state.contactEmail =  action.payload?.client_email ? action.payload?.client_email : '';
            state.contactPhone =  action.payload?.client_mobile ? '+'+action.payload?.client_mobile : '';
            state.loading = 'success';
        });
        builder.addCase(addFamilyReg.rejected, (state, { payload }: any) => {
            state.loading = 'error';

            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addFamilyReg');
            } else if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addFamilyReg');
            } else {
                setNotification(message.CFPCareConnect, message.userAddedfailed, false, 'failed', 'addFamilyReg');
            }

        });



    }
});

// Action creators are generated for each case reducer function
export const { changeLoading, resetImage, resetContactDetails } = familyRegistrationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const FamilyRegistrationSlices = (state: RootState) =>
    state.familyRegistrationData;

export const familyRegistrationReducer = familyRegistrationSlice.reducer;