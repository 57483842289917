import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import menu from '../../assets/images/menuIcon.svg'
import complete from '../../assets/images/completeIcon.svg'
import incomplete from '../../assets/images/inComplete.svg'
import editIcon from '../../assets/images/edit.svg'
import sent from '../../assets/images/sentIcon.svg'
import searchIcon from '../../assets/images/searchIcon.svg'
import ButtonType from '../button/ButtonType'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Avatar, IconButton, Tooltip } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_CLIENT_LIST, ROUTE_DASHBOARD } from '../../config/routeConfig'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { CommonSlices } from '../../pages/common/commonSlice'
import { loginPageSlices } from '../../pages/loginPage/loginPageSlice'
import { IFetchProfilePic, IMarkAsComplete, IMessageList, ISingleMessage } from '../../models/dashboardInterfaces'
import { fetchCompletedMessage, fetchInboxMessage, fetchInboxNewMessage, fetchProfilePic, fetchSendDashboardMessage, fetchSendMessage, handleMarkAsComplete, handleSingleMessage } from '../../services/dashboardServices'
import { DashboardSlices, changeMessageTab, setResetMessageValues, setSidebarSearch, setSidebarfilterOption } from '../../pages/dashboard/dashboardSlice'
import dayjs from 'dayjs'
import { LBL_NURSING_STAFF, LBL_PATIENTS, LBL_PROVIDERS, MSG_ACTION_COMPLETE, MSG_ACTION_INCOMPLETE, MSG_CLIENT_ADMIN, MSG_CLIENT_ASSOCIATE, MSG_FILTER_ALL, MSG_FILTER_MESSAGE, MSG_FILTER_ORDERS, MSG_FILTER_READ, MSG_FILTER_UNREAD, MSG_GENERAL, MSG_NURSING_STAFF, MSG_PATIENTS, MSG_PROVIDER, MSG_RELATIVES, MSG_TYPE_GENERAL, MSG_TYPE_INDIVIDUAL, MSG_TYPE_ORDER, NURSING_STAFF_SPACE, PATIENTS, PROVIDER, P_ACT_ALLOW, ROLE_COMPLETED_MESSAGES, ROLE_CREATE_SEND_MESSAGES, ROLE_VIEW_GENERAL_MESSAGES, ROLE_VIEW_INBOX_SENT_MESSAGES, ROLE_VIEW_ORDER, USER_ROLE_CLIENT_ADMIN, USER_ROLE_PATIENTS, USER_ROLE_PROVIDER, USER_ROLE_RELATIVES, useHasActionPermission } from '../../config/permissions'
import SelectField from '../inputFeild/SelectField'
import { convertHtmlToString } from '../../helpers/validators'
import { NotificationListSlices, setNewNotification, setSelectedEmail } from '../../pages/notificationList/notificationSlice';
import { handleMarkNotification } from '../../services/notificationListServices'

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, unid, logedUsername, logedUserID, loginUserRole, logedUserProfilePic } = useAppSelector(loginPageSlices);
  const { navFacility } = useAppSelector(CommonSlices);
  const { selectedmail, newNotification } = useAppSelector(NotificationListSlices);
  const { messageInboxData, messageCompletedData, sentMessageData, loading, selectedMessageTab, paginationInboxCount, messageNewData,avatarUrls } = useAppSelector(DashboardSlices);
  const dispatch = useAppDispatch();
  const messageContainerRef = useRef<HTMLDivElement>(null);

  //PERMISSIONS
  const hasCreateMessagePermission = useHasActionPermission(ROLE_CREATE_SEND_MESSAGES, P_ACT_ALLOW);
  const hasViewInboxSentPermission = useHasActionPermission(ROLE_VIEW_INBOX_SENT_MESSAGES, P_ACT_ALLOW);
  const hasViewCompletedPermission = useHasActionPermission(ROLE_COMPLETED_MESSAGES, P_ACT_ALLOW);
  const hasViewGeneralMessagePermission = useHasActionPermission(ROLE_VIEW_GENERAL_MESSAGES, P_ACT_ALLOW);
  const hasViewOrderPermission = useHasActionPermission(ROLE_VIEW_ORDER, P_ACT_ALLOW);

  const asideOptions = [
    { title: "Inbox", icon: menu, id: 1, permission: hasViewInboxSentPermission },
    // { title: "Pending", icon: clock },
    { title: "Completed", icon: complete, id: 2, permission: hasViewCompletedPermission && (loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) },
    { title: "Sent", icon: sent, id: 3, permission: hasViewInboxSentPermission },
  ];
  const [tab, setTab] = useState<number>(1);
  const [selectedMsgId, setSelectedMsgId] = useState<number>();
  const [messageInboxListData, setmessageInboxListData] = useState(messageInboxData.map(obj => ({ ...obj, isChecked: false })));
  const [messageCompleteData, setmessageCompleteData] = useState(messageCompletedData.map(obj => ({ ...obj, isChecked: false })));
  const [messageSentListData, setmessageSentListData] = useState(sentMessageData);
  const [search, setSearch] = useState<string>("");
  const [filterOption, setFilterOption] = useState<string>("0");
  const [messageCount, setMessageCount] = useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);

  //pagination
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 25;

  const inboxTab = 1;
  const completedTab = 2;
  const sentTab = 3;


  //For update message list when recieving new message
  // Function to trigger when a new message is received
  useEffect(() => {
    if (newNotification) {

      const req: IMessageList = {
        unid, token,
        facility: navFacility,
        user_id: logedUserID,
        page: currentPage,
        search: search,
        filter: Number(filterOption)
      };
      if (tab === inboxTab) {
        dispatch(fetchInboxNewMessage(req));
      }

      dispatch(setNewNotification(false));
    }
  }, [newNotification]);


  //To trigger when only a new message is received (in order to avoid a refreshing issue!!!).
  useEffect(() => {
    // const existingId = messageInboxListData.map(item => item.id.toString());
    // const hasNewMsg = messageNewData.every(element => existingId.includes(element.toString()));
    const existingIds = messageInboxListData.map(item => item.id.toString());
    const hasNewMsg = messageNewData.every(element => existingIds.includes(element.id.toString()));



    // if (!hasNewMsg) {
    //   callMessageList(tab);
    // }

    if (!hasNewMsg) {
      //Extracting the new message.
      const newMessages = messageNewData.map(obj => ({ ...obj, isChecked: false })).filter(obj => !existingIds.includes(obj.toString()));
      //Sorting with time and removing old messages that correspond with new messages.
      let newMessagesSorted = [...newMessages, ...messageInboxListData].sort((a, b) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()).filter(item => messageNewData.map(opt => opt.id.toString()).includes(item.id.toString()));

      // Create an object to track unique IDs
      const seenIds: { [id: number]: boolean } = {};

      // Filter out duplicates based on the "id" property
      newMessagesSorted = newMessagesSorted.filter(obj => {
        if (!seenIds[obj.id]) {
          seenIds[obj.id] = true;
          return true;
        }
        return false;
      });

      setmessageInboxListData(newMessagesSorted);
      handleProfilePic(Array.from(new Set(newMessagesSorted.map(item => Number(item.id))))); //inorder set profile_piic
      setMessageCount(newMessagesSorted.length);
      setUnreadMessageCount(newMessagesSorted.filter((item: { is_read: boolean }) => item.is_read === false).length);
    }



  }, [messageNewData]);




  //setting up data to list when api calls
  useEffect(() => {

    if (currentPage > 0 && navFacility.length > 0) {
      callMessageList(tab);
    } else {
      setCurrentPage(1)
    }
  }, [currentPage]);

  useEffect(() => {
    if (navFacility && navFacility.length > 0 && currentPage > 0) {
      callMessageList(tab);
    }
  }, [navFacility])

  //initial fetching the data to display message list
  useEffect(() => {
    if (loading === 'markedSuccess') {
      const req: IMessageList = {
        unid, token, facility: navFacility,
        user_id: logedUserID,
        page: currentPage,
        search: search,
        filter: Number(filterOption)
      };
      if (tab === inboxTab) {
        dispatch(fetchInboxMessage(req));
      } else if (tab === completedTab) {
        dispatch(fetchCompletedMessage(req));
      }
      setSelectedMsgId(0);
      dispatch(changeMessageTab(0));
    }
    if (selectedMessageTab === 0) {
      setSelectedMsgId(0);
    }
  }, [loading, selectedMessageTab]);

  //to get update sent message list if it is sent msg tab
  useEffect(() => {
    if (loading === 'messageSented' && tab === sentTab) {
      setCurrentPage(0);

      setTimeout(() => {
        setCurrentPage(1);
      }, 100);
    }
  }, [loading]);


  //setting up data to list when api calls
  useEffect(() => {


    if (tab === inboxTab && loading === 'fetchMessagefully') {
      const arrIdInbox = (messageInboxListData.length > 0) ? messageInboxListData.map(item => { return item.id }) : [];
      const arrayWithCheckedInbox = messageInboxData.filter(item => !arrIdInbox.includes(item.id)).map(obj => ({ ...obj, isChecked: false }));

      if (currentPage > 0) {
        if (currentPage === 1) {
          setmessageInboxListData(messageInboxData.map(obj => ({ ...obj, isChecked: false })));
          handleProfilePic(Array.from(new Set(messageInboxData.map(item => Number(item.id))))); //inorder set profile_piic
        } else if (currentPage > 1) {
          setmessageInboxListData([...messageInboxListData, ...arrayWithCheckedInbox]);
          handleProfilePic(Array.from(new Set([...messageInboxListData, ...arrayWithCheckedInbox].map(item => Number(item.id))))); //inorder set profile_pic
        }
      } else {
        setCurrentPage(1);
        setmessageInboxListData([]);
        dispatch(changeMessageTab(0));
      }

      //To set message count
      if (currentPage > 0) {
        let countArray: any = [];
        if (currentPage === 1) {
          countArray = messageInboxData.filter((item) =>
            (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
            !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission));
        } else if (currentPage > 1) {
          countArray = [...messageInboxListData, ...arrayWithCheckedInbox].filter((item) =>
            (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
            !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission));
        }
        setMessageCount(countArray.length);
        setUnreadMessageCount(countArray.filter((item: { is_read: boolean }) => item.is_read === false).length);

      } else {
        setMessageCount(0);
        setUnreadMessageCount(0);
      }

      if (selectedMsgId === 0 && newNotification) {
        dispatch(changeMessageTab(0));
      }
      // setInitialRender(true);

    }
  }, [messageInboxData]);

  useEffect(() => {

    if (tab === completedTab && loading === 'fetchMessagefully') {

      const arrIdCompleted = (messageCompleteData.length > 0) ? messageCompleteData.map(item => { return item.id }) : [];

      const arrayWithCheckedCompleted = messageCompletedData.filter(item => !arrIdCompleted.includes(item.id)).map(obj => ({ ...obj, isChecked: false }));

      if (currentPage > 0) {
        if (currentPage === 1) {
          setmessageCompleteData(messageCompletedData.map(obj => ({ ...obj, isChecked: false })));
          handleProfilePic(Array.from(new Set(messageCompletedData.map(item => Number(item.id))))); //inorder set profile_piic

        } else if (currentPage > 1) {
          setmessageCompleteData([...messageCompleteData, ...arrayWithCheckedCompleted]);
          handleProfilePic(Array.from(new Set([...messageCompleteData, ...arrayWithCheckedCompleted].map(item => Number(item.id))))); //inorder set profile_pic

        }
        // else {
        //   // setmessageInboxListData([]);
        //   setmessageSentListData([]);
        //   dispatch(changeMessageTab(0));
        // }
      } else {
        setCurrentPage(1);
        setmessageCompleteData([]);
        dispatch(changeMessageTab(0));
      }

      //To set message count
      if (currentPage > 0) {
        let countArray: any = [];
        if (currentPage === 1) {
          countArray = messageCompletedData.filter((item) =>
            (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
            !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission));
        } else if (currentPage > 1) {
          countArray = [...messageCompleteData, ...arrayWithCheckedCompleted].filter((item) =>
            (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
            !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission));
        }

        setMessageCount(countArray.length);
        setUnreadMessageCount(countArray.filter((item: { is_read: boolean }) => item.is_read === false).length);

      } else {
        setMessageCount(0);
        setUnreadMessageCount(0);
      }

      if (selectedMsgId === 0 && newNotification) {
        dispatch(changeMessageTab(0));
      }
      // setInitialRender(true);

    }
  }, [messageCompletedData]);

  useEffect(() => {
    if (tab === sentTab) {
      const arrIds = (messageSentListData.length > 0) ? messageSentListData.map(item => { return item.id }) : [];
      const arrayWithChecked = sentMessageData.filter(item => !arrIds.includes(item.id));
      if (currentPage > 0) {
        if (currentPage === 1) {
          setmessageSentListData(sentMessageData);
        } else if (currentPage > 1) {
          setmessageSentListData([...messageSentListData, ...arrayWithChecked]);
        }
      } else {
        setCurrentPage(1);
        setmessageSentListData([]);
      }
      if (currentPage === 1) {
        setMessageCount(sentMessageData.length);

      } else if (currentPage > 1) {
        setMessageCount([...messageSentListData, ...arrayWithChecked].length);
      }
      // setInitialRender(true);
    }
  }, [sentMessageData]);


  useEffect(() => {
    // Attach a scroll event listener to trigger loading more items when scrolling

    const container = messageContainerRef.current;
    const handleScroll = () => {
      if (container) {
        const { scrollTop, clientHeight, scrollHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight - 500) {
          if (currentPage * itemsPerPage < paginationInboxCount) {
            setCurrentPage(currentPage + 1);
          }
        }
      }
    };
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };

  }, [paginationInboxCount]); // Run this effect only once when the component mounts

  //For select co-responding mail when event is triggered from notification( navbar and notificationlist page)

  useEffect(() => {
    if (selectedmail > 0) {

      const newFieldDetails = messageInboxListData.map((obj) => {
        return selectedmail.toString() === obj.sender_id ? { ...obj, is_read: true } : obj;
      });
      const selectedMsg = messageInboxListData.find((obj) => Number(selectedmail) === Number(obj.sender_id));

      setmessageInboxListData(newFieldDetails);
      setSelectedMsgId(selectedMsg?.id && selectedMsg.id);
      dispatch(changeMessageTab(2));
      const req: ISingleMessage = {
        unid, token,
        facility: Number(selectedMsg?.facility),
        sender_id: selectedMsg?.sender_id ? Number(selectedMsg.sender_id) : 0,
        type: selectedMsg?.message_type ? selectedMsg?.message_type : 0,
        group_name: selectedMsg?.message_groups ? selectedMsg?.message_groups : '',
        user_id: logedUserID,
        message_id: selectedMsg?.id ? selectedMsg.id : 0,
        page: 1
      };
      dispatch(handleSingleMessage(req));
      dispatch(setSelectedEmail(0));
    }

  }, [selectedmail])


  // Function to handle select all in inbox 
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;
    if (id === "selectAll") {

      if (tab === inboxTab) {
        setmessageInboxListData(messageInboxListData.map((option) => (
          // Its is used to avoid selection of general message and place order (need later)
          // (option.message_or_order === MSG_TYPE_ORDER.toString() || Number(option.message_type) === MSG_TYPE_GENERAL) ? option :
          { ...option, isChecked: checked }
        )));
      } else if (tab === completedTab) {
        setmessageCompleteData(messageCompleteData.map((option) => (
          // Its is used to avoid selection of general message and place order (need later)
          // (option.message_or_order === MSG_TYPE_ORDER.toString() || Number(option.message_type) === MSG_TYPE_GENERAL) ? option :
          { ...option, isChecked: checked }
        )));
      }



    } else {

      if (tab === inboxTab) {
        setmessageInboxListData(messageInboxListData.map((option) => (
          (option.id.toString() === id
            //  && option.message_or_order !== MSG_TYPE_ORDER.toString() && Number(option.message_type) !== MSG_TYPE_GENERAL
          ) ?
            { ...option, isChecked: checked } : option
        )));
      } else if (tab === completedTab) {
        setmessageCompleteData(messageCompleteData.map((option) => (
          (option.id.toString() === id
            // && option.message_or_order !== MSG_TYPE_ORDER.toString() && Number(option.message_type) !== MSG_TYPE_GENERAL
          ) ?
            { ...option, isChecked: checked } : option
        )));
      }
    }
  };

  //Function to mark as completed or uncompleted
  const handleComplete = () => {
    const selectedMsgInbox = messageInboxListData.filter(item => item.isChecked).map(item => item.id);
    const selectedMsgCompleted = messageCompleteData.filter(item => item.isChecked).map(item => item.id);

    if (tab === inboxTab && selectedMsgInbox.length > 0) {
      const req: IMarkAsComplete = {
        unid, token, message: selectedMsgInbox, action: MSG_ACTION_COMPLETE
      };
      dispatch(handleMarkAsComplete(req));
    } else if (tab === completedTab && selectedMsgCompleted.length > 0) {
      const req: IMarkAsComplete = {
        unid, token, message: selectedMsgCompleted, action: MSG_ACTION_INCOMPLETE
      };
      dispatch(handleMarkAsComplete(req));
    }

    const newFieldDetailInbox = messageInboxListData.filter(item => !selectedMsgInbox.includes(item.id));
    const newFieldDetailComplete = messageCompleteData.filter(item => !selectedMsgCompleted.includes(item.id));
    if (tab === inboxTab) {
      setmessageInboxListData(newFieldDetailInbox);
    } else if (tab === completedTab) {
      setmessageCompleteData(newFieldDetailComplete);
    }

  }
  // Function to handle select all in inbox 
  const handleSelectMessageType = (id: number) => {
    setFilterOption("0");
    setSearch("");
    setCurrentPage(0);
    navigate(ROUTE_DASHBOARD);
    setTab(id);
    setSelectedMsgId(0);
    dispatch(changeMessageTab(id == sentTab ? id : 0));

    setTimeout(() => {
      setCurrentPage(1);
    }, 100);
  };

  //Fuction to call api coresponding to selected tab
  const callMessageList = (msgType: number) => {

    const req: IMessageList = {
      unid, token,
      facility: navFacility,
      user_id: logedUserID,
      page: currentPage,
      search: search,
      filter: Number(filterOption)
    };
    if (msgType === inboxTab) {
      dispatch(fetchInboxMessage(req));
    } else if (msgType === completedTab) {
      dispatch(fetchCompletedMessage(req));
    } else if (msgType === sentTab) {
      dispatch(fetchSendMessage(req));
      if (currentPage === 1) {
        dispatch(changeMessageTab(3));
        dispatch(fetchSendDashboardMessage(req));
      }
    }
  }

  //Function to select the message 
  const handleSelectMessage = (data: any) => {
    //notification count
    const message_type = Number(data.message_type) === MSG_TYPE_GENERAL ? MSG_TYPE_GENERAL : MSG_TYPE_INDIVIDUAL;
    if (!data.is_read) {
      dispatch(handleMarkNotification({ unid, token, msg_email: data.sender, message_type, user_email: logedUsername }));
    }
    //section sidebar updates
    setSelectedMsgId(data.id);
    const newFieldDetailInbox = messageInboxListData.map((obj) => {
      return data.id === obj.id ? { ...obj, is_read: true } : obj;
    });
    const newFieldDetailComplete = messageCompleteData.map((obj) => {
      return data.id === obj.id ? { ...obj, is_read: true } : obj;
    });
    setUnreadMessageCount(tab === inboxTab ? newFieldDetailInbox.filter(item => item.is_read === false).length : newFieldDetailComplete.filter(item => item.is_read === false).length);
    if (tab === inboxTab) {
      setmessageInboxListData(newFieldDetailInbox);
    } else if (tab === completedTab) {
      setmessageCompleteData(newFieldDetailComplete);
    }

    dispatch(changeMessageTab(2));
    const req: ISingleMessage = {
      unid, token, facility: Number(data.facility), sender_id: Number(data.sender_id), type: data.message_type, group_name: data.message_groups,
      user_id: logedUserID, message_id: data.id, page: 1
    };
    dispatch(handleSingleMessage(req));

  }

  //function to delay api call to search patient
  const debounce = (func: (input: string) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return function (input: string) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(input), delay);
    };
  };

  //Function for handle Search
  const handleSearchFilter = (e: any, filterType: string) => {
    if (filterType === 'search') {
      setSearch(e.target.value);
    } else if (filterType === 'filter') {
      setFilterOption(e.target.value)
    }
    const req: IMessageList = {
      unid, token,
      facility: navFacility,
      user_id: logedUserID,
      page: 1,
      search: filterType === 'search' ? e.target.value : search,
      filter: filterType === 'filter' ? Number(e.target.value) : Number(filterOption)
    };
    if (tab === inboxTab) {
      dispatch(fetchInboxMessage(req));
      dispatch(changeMessageTab(0));
    } else if (tab === completedTab) {
      dispatch(fetchCompletedMessage(req));
      dispatch(changeMessageTab(0));
    } else if (tab === sentTab) {
      dispatch(changeMessageTab(sentTab));
      dispatch(fetchSendMessage(req));
      if (filterType === 'search') {
        const debouncedSetSidebarSearch = debounce((value: string) => {
          dispatch(setSidebarSearch(value));
        }, 1000);
        debouncedSetSidebarSearch(e.target.value);
      } else { dispatch(setSidebarSearch(search)); }

      dispatch(setSidebarfilterOption(filterType === 'filter' ? e.target.value : filterOption));
    }
  }

  //Function to select color  
  const userColor = (parent: any) => {

    switch (parent) {
      case MSG_CLIENT_ADMIN:
        return '#ED9348';
      case MSG_CLIENT_ASSOCIATE:
        return '#F2C94C ';
      case MSG_PATIENTS:
        return '#27AE60 ';
      case MSG_RELATIVES:
        return '#27AE60 ';
      case MSG_NURSING_STAFF:
        return '#2196F3 ';
      case MSG_PROVIDER:
        return '#BB6BD9 ';
      case MSG_GENERAL:
        return '#E0E0E0 ';
      default:
        return 'white'; // Set a default background color class
    }

  }

//Function to get profile pic 

const handleProfilePic =(sender_id:number[])=>{
      //To fetch profile pic of corresponding sender
      const req: IFetchProfilePic = {
        unid, token,
        sender: sender_id,
        // sender: Array.from(new Set(messageInboxData.map(item => Number(item.sender_id)))),
      };
      dispatch(fetchProfilePic(req));
    }

  return (<>{location.pathname !== ROUTE_CLIENT_LIST &&
    <aside className=' max-w-[475px] flex  bg-[var(--color-FFF)] border-r border-[var(--color-EEE)] min-h-[calc(100vh-105px)]'>

      {/* Section of icon */}

      <section className="flex flex-col items-center min-w-[120px]  bg-[var(--color-253179)] ">
        {
          asideOptions.map((item) =>
            item.permission &&
            <div key={item.id} className={`${(tab === item.id && location.pathname === ROUTE_DASHBOARD) ? ' bg-[var(--color-0048D4)] ' : ' opacity-60 '} py-[22px] flex flex-col gap-3 items-center w-full cursor-pointer `} onClick={() => handleSelectMessageType(item.id)}>
              <img src={item.icon} alt={item.title} loading='lazy' decoding='async' />
              <span className='text-sm font-medium text-[var(--color-FFF)]'>{item.title}</span>
            </div>
          )
        }
      </section>

      {/* section where message is shown */}
      {location.pathname === ROUTE_DASHBOARD &&
        <section className='w-full min-w-[350px]'>
          <div className='px-4 pt-[9px] pb-[10px] border-b '>
            <h1 className='text-2xl text-[var(--color-212121)] mb-1'>{tab === inboxTab ? <>Inbox</> : tab === completedTab ? <>Completed</> : <>Sent</>}</h1>
            <div className="flex gap-6 text-xs text-[var(--color-9E9E9E)] mb-[18px]">
              <span>{messageCount} Messages</span>
              {tab !== 3 && <span>{unreadMessageCount} Unread</span>}
            </div>

            {hasCreateMessagePermission &&
              <ButtonType type='iconLeft' buttonLabel='New Message' icon={editIcon} className=' mb-[13px] ' onClick={() => { dispatch(changeMessageTab(1)); setSelectedMsgId(0); dispatch(setResetMessageValues(true)); }} />}

            <div className="flex items-center gap-[9px] bg-[var(--color-white)] border boreder-[var(--color-EEE)] rounded px-3 py-[6px] mb-4">
              <img src={searchIcon} alt="search" loading='lazy' decoding='async' />
              <input type="search" name="" id="" className='focus:outline-none border-none text-xs w-full' placeholder={(loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) ? 'Search' : 'Search Patient'} onChange={(e) => handleSearchFilter(e, 'search')} value={search} />
            </div>
            {(tab === inboxTab) &&
              <div className="flex justify-between items-center" >
                {messageInboxListData.length > 0 &&
                  // !messageInboxListData.every((item) => item.message_or_order === '2' || Number(item.message_type) === MSG_TYPE_GENERAL) &&
                  (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) &&
                  <div className="flex gap-5 text-[var(--color-9E9E9E)] items-center">
                    <input
                      type="checkbox"
                      // className='h-4 w-4 '
                      name={"selectAll"}
                      checked={!messageInboxListData.some((item) => item.isChecked !== true && item.message_or_order !== MSG_TYPE_ORDER.toString() && Number(item.message_type) !== MSG_TYPE_GENERAL)
                      } onChange={(e) => handleChange(e)}
                      id={"selectAll"} />
                    <ArrowDropDownIcon />
                  </div>}
                <div></div>
                <div className="flex gap-6 text-[var(--color-757575)] items-center">
                  {(loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) &&
                    <Tooltip title={"Mark as completed"}>
                      <IconButton onClick={() => handleComplete()}>
                        <AssignmentTurnedInIcon className='h-6 w-6 cursor-pointer' />
                      </IconButton>
                    </Tooltip>}
                  <SelectField
                    className=' select-message py-[6px] rounded border-[var(--color-BDBDBD)] text-[13px] min-w-[112px]'
                    value={filterOption}
                    onChange={(e) => handleSearchFilter(e, 'filter')}
                    options={hasViewOrderPermission ? [{ name: 'All', id: MSG_FILTER_ALL.toString() }, { name: 'Orders', id: MSG_FILTER_ORDERS.toString() }, { name: 'Read', id: MSG_FILTER_READ.toString() }, { name: 'Unread', id: MSG_FILTER_UNREAD.toString() }] : [{ name: 'All', id: MSG_FILTER_ALL.toString() }, { name: 'Read', id: MSG_FILTER_READ.toString() }, { name: 'Unread', id: MSG_FILTER_UNREAD.toString() }]}
                  />
                </div>
              </div>
            }
            {(tab === completedTab) &&
              <div className="flex justify-between items-center" >
                {messageCompleteData.length > 0 &&
                  //  !messageCompleteData.every((item) => item.message_or_order === '2' || Number(item.message_type) === MSG_TYPE_GENERAL) &&
                  (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) &&
                  <div className="flex gap-5 text-[var(--color-9E9E9E)] items-center">
                    <input
                      type="checkbox"
                      // className='h-4 w-4 '
                      name={"selectAll"}
                      checked={!messageCompleteData.some((item) => item.isChecked !== true && item.message_or_order !== MSG_TYPE_ORDER.toString() && Number(item.message_type) !== MSG_TYPE_GENERAL)
                      } onChange={(e) => handleChange(e)}
                      id={"selectAll"} />
                    <ArrowDropDownIcon />
                  </div>}
                <div></div>
                <div className="flex gap-6 text-[var(--color-757575)] items-center">
                  {(loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) &&
                    <Tooltip title={"Mark as incompleted"}>
                      <IconButton onClick={() => handleComplete()}>
                        <img src={incomplete} alt='Mark as incompleted' className='h-6 w-6 cursor-pointer' />
                      </IconButton>
                    </Tooltip>}
                  <SelectField
                    className=' select-message py-[6px] rounded border-[var(--color-BDBDBD)] text-[13px] min-w-[112px]'
                    value={filterOption}
                    onChange={(e) => handleSearchFilter(e, 'filter')}
                    options={[{ name: 'All', id: MSG_FILTER_ALL.toString() }, { name: 'Read', id: MSG_FILTER_READ.toString() }, { name: 'Unread', id: MSG_FILTER_UNREAD.toString() }]}
                  />
                </div>
              </div>
            }
            {(tab === sentTab && (loginUserRole === USER_ROLE_PROVIDER || loginUserRole === USER_ROLE_CLIENT_ADMIN)) &&
              <div className="flex justify-between items-center w-full" >

                <div></div>
                <div>
                  <SelectField
                    className=' select-message py-[6px] rounded border-[var(--color-BDBDBD)] text-[13px] w-[112px]'
                    value={filterOption}
                    onChange={(e) => handleSearchFilter(e, 'filter')}
                    options={[{ name: 'All', id: MSG_FILTER_ALL.toString() }, { name: 'Message', id: MSG_FILTER_MESSAGE.toString() }, { name: 'Orders', id: MSG_FILTER_ORDERS.toString() }]}
                  />
                </div>
              </div>
            }
          </div>
          <div className='h-auto max-h-[1000px]  custom-scrollBar ' ref={messageContainerRef}>

            {
              (tab === inboxTab) ?
                messageInboxListData.filter((item) => {
                  const hasPermission =
                    (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
                    !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission);
                  return hasPermission;
                }).length > 0 ?
                  messageInboxListData.filter((item) => {
                    const hasPermission =
                      (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
                      !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission);

                    return hasPermission;
                  }).map(item => <>
                    <div
                      key={item.id}
                      className={`flex border-b pl-4 hover:bg-[var(--color-EBF6FF)]  gap-3 ${selectedMsgId === item.id ? " bg-[var(--color-EBF6FF)] " : Number(item.message_type) === MSG_TYPE_GENERAL ? " bg-[var(--color-FFFCEE)] " : item.is_read ? " bg-[var(--color-FAFAFA)] " : "  "}`}>

                      <div className='pt-[33px] '>
                        {
                          // item.message_or_order !== MSG_TYPE_ORDER.toString() && Number(item.message_type) !== MSG_TYPE_GENERAL &&
                          (loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) ? <input
                            type="checkbox"
                            name={item.sender_name}
                            checked={item.isChecked}
                            onChange={(e) => handleChange(e)}
                            id={item.id.toString()}
                          // className='h-4 w-4'
                          /> : <div className='h-5 w-5' ></div>}
                      </div>
                      <div className='py-6  pr-4 flex gap-3 cursor-pointer w-full z-[2]'
                        onClick={() => handleSelectMessage(item)} >

                        {
                        (item.receiver_group === "" && item.receiver_name === "") ?
                         (
                            <Avatar className='h-9 w-9 ' alt={item.sender_name} src={avatarUrls[item.id] || ''} sx={{ border: `3px solid ${userColor(item.sender_group)}` }} >
                              {item.sender_name?.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                            </Avatar>
                          ) :
                           (
                            <Avatar className='h-9 w-9 ' alt={item.sender_name} src={avatarUrls[item.id] || ''} sx={{ border: `3px solid ${userColor(item.receiver_group)}` }} >
                              {item.receiver_name?.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                            </Avatar>
                          )
                        }

                        <div className='w-full '>
                          <div className="flex justify-between mb-3 items-center ">
                            <h2 className='text-sm text-[var(--color-424242)] font-medium'>{Number(item.message_type) === MSG_TYPE_GENERAL ? <>General </> : (item.receiver_group === "" && item.receiver_name === "" || (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES)) ? item.sender_name : item.receiver_name}
                            </h2>
                            <span className='text-[var(--color-9E9E9E)] text-xs'>
                              {dayjs().diff(item.created_on, 'day') !== 0 ? `${dayjs(item.created_on).format('D MMM ')}` : `${dayjs(item.created_on).format(`hh:mm A`)}`}

                            </span>
                          </div>
                          <div><p className={`text-[13px] ${item.is_read ? ' text-[var(--color-9E9E9E)] ' : "text-[var(--color-212121)] "} `}>{item.priority === '2' && <span className='text-[var(--color-danger)]'>!</span>} {item.content.length > 50 ? `${convertHtmlToString(item.content)?.replace(/\s+/g, ' ').slice(0, 50)}...` : convertHtmlToString(item.content)}
                          </p></div>

                          {/* {(item.receiver_group !== "" && item.receiver_name !== "" && loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) &&  */}
                          <div className='flex items-center mt-3'>
                            <p className='text-xs text-[var(--color-9E9E9E)] mr-2'>By</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 6 7" fill="none">
                              <circle cx="3" cy="3.5" r="3" fill={`${userColor(item.sender_group)}`} />
                            </svg>
                            <p className='text-xs text-[var(--color-424242)] ml-1'>{item.sender_name}</p>
                          </div>
                          {/* } */}

                        </div>
                      </div>
                    </div>
                  </>) : (
                    <div className="flex border-b items-center justify-center px-4 py-6 gap-3 bg-[var(--color-EBF6FF)]">
                      <h2 className='text-sm  text-[var(--color-424242)] font-medium'>No Messages</h2>
                    </div>
                    // : <div className='w-full py-4 grid place-content-center '><CircularProgress /></div>
                  ) :
                (tab === completedTab) ?
                  messageCompleteData.filter((item) => {
                    const hasPermission =
                      (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
                      !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission);
                    return hasPermission;
                  }).length > 0 ?
                    messageCompleteData.filter((item) => {
                      const hasPermission =
                        (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) &&
                        !(item.message_or_order === MSG_TYPE_ORDER.toString() && !hasViewOrderPermission);

                      return hasPermission;
                    }).map(item => <>
                      <div
                        key={item.id}
                        className={`flex border-b pl-4 hover:bg-[var(--color-EBF6FF)]  gap-3 ${selectedMsgId === item.id ? " bg-[var(--color-EBF6FF)] " : Number(item.message_type) === MSG_TYPE_GENERAL ? " bg-[var(--color-FFFCEE)] " : item.is_read ? " bg-[var(--color-FAFAFA)] " : "  "}`}>

                        <div className='pt-[33px] '>
                          {
                            // (item.message_or_order !== MSG_TYPE_ORDER.toString() && Number(item.message_type) !== MSG_TYPE_GENERAL &&
                            (loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) ? <input
                              type="checkbox"
                              name={item.sender_name}
                              checked={item.isChecked}
                              onChange={(e) => handleChange(e)}
                              id={item.id.toString()}
                            // className='h-4 w-4' 
                            /> : <div className='h-5 w-5' ></div>}
                        </div>
                        <div className='py-6  pr-4 flex gap-3 cursor-pointer w-full z-[2]'
                          onClick={() => handleSelectMessage(item)} >

                          {(item.receiver_group === "" && item.receiver_name === "") ?
                            (
                              <Avatar className='h-9 w-9 ' alt={item.sender_name} src={avatarUrls[item.id] || ''} sx={{ border: `3px solid ${userColor(item.sender_group)}` }}> 
                                {item.sender_name?.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                              </Avatar>
                            ) :(
                              <Avatar className='h-9 w-9 ' alt={item.sender_name} src={avatarUrls[item.id] || ''} sx={{ border: `3px solid ${userColor(item.receiver_group)}` }} >
                                {item.receiver_name?.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                              </Avatar>
                            )
                          }

                          <div className='w-full '>
                            <div className="flex justify-between mb-3 items-center ">
                              <h2 className='text-sm text-[var(--color-424242)] font-medium'>{Number(item.message_type) === MSG_TYPE_GENERAL ? <>General </> : (item.receiver_group === "" && item.receiver_name === "" || (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES)) ? item.sender_name : item.receiver_name}
                              </h2>
                              <span className='text-[var(--color-9E9E9E)] text-xs'>
                                {dayjs().diff(item.created_on, 'day') !== 0 ? `${dayjs(item.created_on).format('D MMM ')}` : `${dayjs(item.created_on).format(`hh:mm A`)}`}
                              </span>
                            </div>
                            <div><p className={`text-[13px] ${item.is_read ? ' text-[var(--color-9E9E9E)] ' : "text-[var(--color-212121)] "} `}>{item.priority === '2' && <span className='text-[var(--color-danger)]'>!</span>} {item.content.length > 50 ? `${convertHtmlToString(item.content)?.replace(/\s+/g, ' ').slice(0, 50)}...` : convertHtmlToString(item.content)}
                            </p></div>

                            {/* {(item.receiver_group !== "" && item.receiver_name !== "" && loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) && */}
                            <div className='flex items-center mt-3'>
                              <p className='text-xs text-[var(--color-9E9E9E)] mr-2'>By</p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 6 7" fill="none">
                                <circle cx="3" cy="3.5" r="3" fill={`${userColor(item.sender_group)}`} />
                              </svg>
                              <p className='text-xs text-[var(--color-424242)] ml-1'>{item.sender_name}</p>
                            </div>
                            {/* } */}

                          </div>
                        </div>
                      </div>
                    </>) : (<div className="flex border-b items-center justify-center px-4 py-6 gap-3 bg-[var(--color-EBF6FF)]">
                      <h2 className='text-sm  text-[var(--color-424242)] font-medium'>No Messages</h2>
                    </div>
                      // : <div className='w-full py-4 grid place-content-center '><CircularProgress /></div>
                    ) :

                  messageSentListData.length > 0 ?
                    messageSentListData.filter(item => (Array.isArray(item.receiver) && item.receiver.some(r => typeof r === 'string'))).map(item => <>
                      <div
                        key={item.id}
                        className={`flex border-b px-4 hover:bg-[var(--color-EBF6FF)] py-6 gap-3 `}
                        onClick={() => dispatch(changeMessageTab(3))}
                      >
                       
                          <Avatar className='h-9 w-9' alt={logedUsername} src={logedUserProfilePic} >
                            {logedUsername.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                          </Avatar>
                      

                        <div className='w-full'>
                          <div className="flex justify-between mb-3 items-center ">
                            <h2 className='text-sm text-[var(--color-424242)] font-medium'>{item.receiver?.[0] === NURSING_STAFF_SPACE ? LBL_NURSING_STAFF : item.receiver?.[0] === PROVIDER ? LBL_PROVIDERS : item.receiver?.[0] === PATIENTS ? LBL_PATIENTS : item.receiver?.[0]}
                              {item.receiver.length > 1 && <span className='text-[var(--color-9E9E9E)] ml-1'>+{item.receiver.length - 1}</span>}
                            </h2>
                            <span className='text-[var(--color-9E9E9E)] text-xs'>
                              {dayjs().diff(item.created_on, 'day') !== 0 ? `${dayjs(item.created_on).format('D MMM ')}` : `${dayjs(item.created_on).format(`hh:mm A`)}`}
                            </span>
                          </div>
                          <div><p className={`text-[13px]  text-[var(--color-212121)] `}>{item.priority === '2' && <span className='text-[var(--color-danger)]'>!</span>} {item.content.length > 50 ? `${convertHtmlToString(item.content)?.replace(/\s+/g, ' ').slice(0, 50)}...` : convertHtmlToString(item.content)} </p></div>
                        </div>

                      </div>
                    </>) :
                    (<div className="flex border-b items-center justify-center px-4 py-6 gap-3 bg-[var(--color-EBF6FF)]">
                      <h2 className='text-sm  text-[var(--color-424242)] font-medium'>No Messages</h2>
                    </div>
                      // : <div className='w-full py-4 grid place-content-center '><CircularProgress /></div>
                    )
            }




          </div >
        </section>}

    </aside>
  }</>
  )
}

export default SideBar