import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import logo from '../../assets/images/careConnectIcon.png'
import arrow from '../../assets/images/selectArrow.svg'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Avatar, Badge, IconButton } from '@mui/material';
import { ROUTE_ASSOCIATE_USERLIST, ROUTE_DASHBOARD, ROUTE_FACILITY_LIST, ROUTE_NOTIFICATION_LIST, ROUTE_NURSING_STAFF_LIST, ROUTE_PATIENT_LIST, ROUTE_PROFILE, ROUTE_PROVIDER_LIST, ROUTE_ROLE_PERMISSION, ROUTE_TIMESHEET } from '../../config/routeConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { changeGenerateFirebaseToken, loginPageSlices, saveFirebaseToken } from '../../pages/loginPage/loginPageSlice';
import { handleLogoutData } from '../../services/loginService';
import { IFacilityDropdownState } from '../../models/commonInterfaces';
import { fetchFacilityDropdown, fetchStateData } from '../../services/commonServices';
import { CommonSlices, changeNavFacility } from '../../pages/common/commonSlice';
import { ROLE_CLIENT_ASSOCIATE_USER, ROLE_FACILITY, ROLE_NURSING_STAFF, ROLE_PATIENTS_RELATIVES, ROLE_PROVIDER, ROLE_ROLE_AND_PERMISSIONS, ROLE_REPORTS, USER_ROLE_SUPERADMIN, useHasViewPermission, LBL_PROVIDERS, LBL_NURSING_STAFF, LBL_ASSOCIATES, LBL_ROLE_PERMISSIONS, LBL_TIMESHEET, LBL_FACILITIES } from '../../config/permissions';

import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import { getMessaging, getToken } from 'firebase/messaging';
import { onMessageListener } from '../../config/firebase';
import { INotificationState } from '../../models/notificationListInterfaces';
import { fetchNotification, markNotificationAllRead, setFirebaseToken } from '../../services/notificationListServices';
import { NotificationListSlices, setNewNotification, setSelectedEmail } from '../../pages/notificationList/notificationSlice';
import { setNotification } from '../notification/ToastNotification';
import { message } from '../../utilities/locals/messages';



const Navbar = () => {
    const maxWords = 25;
    const navigate = useNavigate();
    const location = useLocation();
    const messaging = getMessaging();
    const dispatch = useAppDispatch();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { loginUserRole, unid, uuid, token, logedUsername, firebaseToke, generateFirebaseToken, loginUserName, client, logedUserProfilePic, loading: loginLoader, } = useAppSelector(loginPageSlices);
    const { facilityData } = useAppSelector(CommonSlices);
    const { notificationData, markAsRead } = useAppSelector(NotificationListSlices);

    const moreOptions = [
        { label: LBL_FACILITIES, value: ROUTE_FACILITY_LIST, permission: useHasViewPermission(ROLE_FACILITY) },
        { label: LBL_PROVIDERS, value: ROUTE_PROVIDER_LIST, permission: useHasViewPermission(ROLE_PROVIDER) },
        { label: LBL_NURSING_STAFF, value: ROUTE_NURSING_STAFF_LIST, permission: useHasViewPermission(ROLE_NURSING_STAFF) },
        { label: LBL_ASSOCIATES, value: ROUTE_ASSOCIATE_USERLIST, permission: useHasViewPermission(ROLE_CLIENT_ASSOCIATE_USER) },
        { label: LBL_TIMESHEET, value: ROUTE_TIMESHEET, permission: useHasViewPermission(ROLE_REPORTS) },
        { label: LBL_ROLE_PERMISSIONS, value: ROUTE_ROLE_PERMISSION, permission: useHasViewPermission(ROLE_ROLE_AND_PERMISSIONS) },
    ]
    const allPermissionsFalse = moreOptions.every(option => option.permission === false);




    const [selectedOptions, setSelectedOptions] = useState<{ name: string; id: string; isChecked: boolean; }[]>(facilityData);
    const [selectedLabel, setSelectedLabel] = useState('');
    const [showUser, setshowUser] = useState<boolean>(false);
    const [logoutClick, setLogoutClick] = useState<boolean>(true);

    //###########################
    //#######notifiation-start###
    //###########################

    const [notifications, setNotifications] = useState<INotificationState[]>([]);
    const [notificationCount, setnotificationCount] = useState<number>(0);
    const [openNotification, setOpenNotification] = useState<boolean>(false);

    //To fetch a firebase token
    useEffect(() => {
        if (generateFirebaseToken) {
            requestForToken();
            dispatch(changeGenerateFirebaseToken(false));
        }
        dispatch(fetchNotification({ token, unid, page: 1 }));

    }, [generateFirebaseToken]);


    //for the set count and list notification
    useEffect(() => {
        setNotifications(notificationData);
        setnotificationCount(notificationData.filter(item => !item.is_read).length);
    }, [notificationData]);

    //after clicked on sidebar need to update count
    useEffect(() => {
        if (markAsRead !== 0) {
            dispatch(fetchNotification({ token, unid, page: 1 }));
        }
    }, [markAsRead]);


    //To update, count when a message is updated when the user is not in the CFP web tab.
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                dispatch(fetchNotification({ token, unid, page: 1 }));
                dispatch(setNewNotification(true));
            }
        };

        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup: remove the event listener when the component is unmounted
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    // Function to trigger when a new message is received
    onMessageListener().then(() => {
        dispatch(setNewNotification(true));
        dispatch(fetchNotification({ token, unid, page: 1 }));

    }).catch((err) => console.log('failed: ', err));




    //Function to create a firebase token
    const requestForToken = () => {
        return getToken(messaging, { vapidKey: 'BIVV9rrfpZ6g006jr9RYjmr29RJzjvld4P3SgRhGZDjOGETJAz0gcercSMgav4ReqVsFEgwYRMcGTVlM7NKRNw8' })
            .then((currentToken) => {
                if (currentToken) {
                    dispatch(setFirebaseToken({ token, unid, firebase_token: currentToken, device_type: 1 }));
                    dispatch(saveFirebaseToken(currentToken));
                    // Perform any other neccessary action with the token
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    };
    // function to change is_read status while opening a navbar dropdown.
    const handleOpenNotification = () => {
        if (notificationCount > 0) {
            dispatch(markNotificationAllRead({ token, unid }));
        }
        setnotificationCount(0);
        // setShowNotification(false);
        setOpenNotification(true);
    };

    const handleMessageClick = (sender_id: number) => {
        navigate(ROUTE_DASHBOARD);
        dispatch(setSelectedEmail(sender_id));
    }
    
    const handleIsReadInNotification = () => {
        // setShowNotification(true);
        dispatch(fetchNotification({ token, unid, page: 1 }));
        setOpenNotification(false);
    }
    //###########################
    //#######notifiation-stop###
    //###########################


    useEffect(() => {
        const req: IFacilityDropdownState = {
            unid, token, client: client, group: loginUserRole, user: logedUsername
        };
        dispatch(fetchStateData(req));
        if ((loginUserRole !== USER_ROLE_SUPERADMIN)) {
            dispatch(fetchFacilityDropdown(req));
        }
    }, [])

    useEffect(() => {
        if (facilityData && facilityData.length > 0) {
            const updatedOptions = facilityData.map((option) => ({ ...option, isChecked: true }));
            setSelectedOptions(updatedOptions);
            if (facilityData.length > 1) {
                setSelectedLabel('All facilities');
            } else {
                setSelectedLabel(facilityData[0].name);
            }
            dispatch(changeNavFacility([0]));
        }
    }, [facilityData])


    useEffect(() => {
        const closeSelects = (e: MouseEvent) => {
            if (!e.target || !(e.target instanceof Node)) return;
            if (!dropdownRef.current?.contains(e.target as Node)) {
                setshowUser(false);
            }
        };

        document.addEventListener('mousedown', closeSelects);

        return () => {
            document.removeEventListener('mousedown', closeSelects);
        };
    }, []);

    useEffect(() => {
        if (loginLoader === "logOutSuccess" || loginLoader === "logOutError") {
            setLogoutClick(true);
        }
    }, [loginLoader]);


    // Function to handle changes in the dropdown elements (checkboxes) 
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, id } = e.target;

        if (id === "selectAll") {
            const updatedOptions = facilityData.map((option) => ({ ...option, isChecked: checked }));
            setSelectedOptions(updatedOptions);
            if (checked) {
                setSelectedLabel('All facilities');
                dispatch(changeNavFacility([0]));
            } else {
                const firstOption = facilityData[0];
                const updatedOptions = facilityData.map((option, index) => ({
                    ...option,
                    isChecked: index === 0, // Select only the first option
                }));
                setSelectedOptions(updatedOptions);
                setSelectedLabel(firstOption.name);
                dispatch(changeNavFacility([Number(firstOption.id)]));
                setNotification(message.CFPCareConnect, message.atleastOneFacility, false, 'failed', 'changeNavFacility');
            }
        } else {
            const updatedOptions = selectedOptions.map((option) =>
                option.id === id ? { ...option, isChecked: checked } : option
            );
            const ids = updatedOptions.filter(option => option.isChecked).map(item => parseInt(item.id, 10));
            if (ids.length === 0) {
                setNotification(message.CFPCareConnect, message.atleastOneFacility, false, 'failed', 'changeNavFacility');
            } else {
                dispatch(changeNavFacility(ids));

                setSelectedOptions(updatedOptions);

                const selectedData = updatedOptions.filter((item) => item.isChecked);
                const selectedNames = selectedData[0]?.name;
                const count = selectedData.length;

                if (updatedOptions.every(item => item.isChecked === true)) {
                    setSelectedLabel('All facilities');
                } else {
                    setSelectedLabel(
                        count > 0 ? `${selectedNames.length > maxWords ? (
                            selectedNames.slice(0, maxWords) + '...'
                        ) : (
                            selectedNames
                        )} ${count > 1 ? `( +${count - 1} )` : ""}` : ''
                    );
                }

            }


        }
    };


    const handleLogout = () => {
        if (logoutClick) {
            dispatch(handleLogoutData({ unid, uuid, firebase_token: firebaseToke, device_type: 1 }));
        }
        setLogoutClick(false);
    }

    return (
        <header className={` z-50 border-b border-[var(--color-EEE) bg-[var(--color-white)]`}>


            <nav className="flex items-center px-8 py-6 " aria-label="Global">
                <div className='flex items-center justify-between w-full '>

                    <div className='flex items-center w-[450px]'>
                        <img
                            className={`h-[57px] w-auto max-w-[65px] ${loginUserRole !== USER_ROLE_SUPERADMIN && ' cursor-pointer '}`}
                            src={logo}
                            alt=""
                            loading='lazy'
                            decoding='async'
                            onClick={() =>  (loginUserRole !== USER_ROLE_SUPERADMIN) && navigate(ROUTE_DASHBOARD) }
                        />
                        {(loginUserRole === USER_ROLE_SUPERADMIN) && <h1 className='text-[var(--color-040E25)] text-xl font-medium tracking-[0.05px] ms-6'>CFP Care Connect</h1>}

                        {(loginUserRole !== USER_ROLE_SUPERADMIN && facilityData.length > 0) && <>
                            <div className='mx-6 bg-[var(--color-BDBDBD)] h-[40px] w-[.5px]'></div>

                            <div className='common-dropdown '>
                                <div className='extra-space '></div>
                                <div className='text-base font-medium tracking-[0.05px] text-[var(--color-212121)]'>

                                    {selectedLabel}

                                </div>

                                <div className='dropdown-list min-w-[250px] '>
                                    <ul className="custom-scrollBar max-h-[250px]">
                                        {facilityData.length > 1 && <li
                                            key={"selectAll"}
                                            className={`${!selectedOptions.some((item) => item.isChecked !== true) ? "text-[var(--color-0048D4)]" : "text-[var(--color-757575)]"
                                                } text-base font-medium tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer me-6`}
                                        >
                                            <label htmlFor="selectAll" className='flex items-center gap-2 justify-start'>
                                                <input
                                                    type="checkbox"
                                                    name={"selectAll"}
                                                    checked={!selectedOptions.some((item) => item.isChecked !== true)}
                                                    onChange={(e) => handleChange(e)}
                                                    id={"selectAll"}
                                                />
                                                All facilities
                                            </label>
                                        </li>}
                                        {selectedOptions.map(option => (
                                            <li
                                                key={option.id}
                                                className={`${option.isChecked ? "text-[var(--color-0048D4)]" : "text-[var(--color-757575)]"
                                                    } text-base font-medium tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer me-6`}
                                            >
                                                <label htmlFor={option.id} className='flex items-center gap-2 justify-start'>
                                                    <div className=' flex items-center justify-center'>
                                                        <input
                                                            type="checkbox"
                                                            name={option.name}
                                                            checked={option.isChecked}
                                                            onChange={(e) => handleChange(e)}
                                                            id={option.id}
                                                        />
                                                    </div>

                                                    {option.name}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                 <img src={arrow} alt="arrow" loading='lazy' decoding='async' height={24} width={24} className='ml-8' />
                            </div>
                        </>}
                    </div>

                    <div className="flex gap-12 text-base items-center font-medium tracking-[0.05px] text-[var(--color-757575)]">
                        {(loginUserRole !== USER_ROLE_SUPERADMIN) &&
                            <div className={`cursor-pointer ${location.pathname === ROUTE_DASHBOARD && 'active-nav'}`} onClick={() => navigate(ROUTE_DASHBOARD)}><span>Messages</span></div>}
                        {useHasViewPermission(ROLE_PATIENTS_RELATIVES) &&
                            <div className={`cursor-pointer ${location.pathname === ROUTE_PATIENT_LIST && 'active-nav'}`} onClick={() => navigate(ROUTE_PATIENT_LIST)} ><span>Patients/Family</span></div>}

                        {(loginUserRole !== USER_ROLE_SUPERADMIN && !allPermissionsFalse) &&
                            <div className={`common-dropdown ${moreOptions.some(item => location.pathname.includes(item.value)) && 'active-nav'}`}>
                                <div className='extra-space '></div>
                                <div className=''>
                                    More
                                </div>

                                <div className={`dropdown-list min-w-[223px] left-0 ${moreOptions.some(item => location.pathname.includes(item.value)) && 'top-12'}`}>
                                    <ul className="custom-scrollBar max-h-[250px] ">
                                        {moreOptions.map(option => (
                                            (option.permission) &&
                                            <li
                                                key={option.value}
                                                className={`${location.pathname === option.value ? " text-[var(--color-0048D4)] " : " text-[var(--color-757575)]"} hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                onClick={() => navigate(option.value)}
                                            >
                                                {option.label}
                                            </li>

                                        ))}
                                    </ul>
                                </div>
                                <img src={arrow} alt="arrow" loading='lazy' decoding='async' height={24} width={24} className='ml-[18px]' />
                            </div>}

                    </div>

                    <div className='flex gap-9 items-center'>

                        {(loginUserRole !== USER_ROLE_SUPERADMIN) &&
                            <div className={`common-dropdown `}>
                                <div className='extra-space '></div>

                                <IconButton onClick={handleOpenNotification}>
                                    <Badge color="error" badgeContent={notificationCount} max={999} overlap="circular">
                                        <NotificationsOutlinedIcon sx={{ height: "40px", width: "40px", color: "var(--color-424242)", cursor: 'pointer' }} />
                                    </Badge>
                                </IconButton>



                                {notifications.length > 0 && <div
                                    className={`${openNotification ? 'dropdown-list min-w-[340px] top-[58px] right-0 ' : ' hidden'}  `}
                                    // className={`dropdown-list min-w-[340px] top-12 right-0 `}
                                    onMouseLeave={() => handleIsReadInNotification()}>
                                    <ul className="custom-scrollBar max-h-[525px] ">
                                        {notifications.slice(0, 9).map((item, index) => (
                                            <>
                                                <li
                                                    key={index + item.created_on}
                                                    className={`${!item.is_read && " bg-[var(--color-E5EDFB)]"} hover:text-[var(--color-0048D4)] cursor-pointer me-6 p-[6px] rounded mb-[6px]`}
                                                    onClick={() => handleMessageClick(item.sender_id)}
                                                >
                                                    <div className='flex items-center gap-[14px]'>
                                                        <Avatar src={item.profile_picture} alt={item.name} sx={{ border: "2px solid white" }} />

                                                        <div className="flex flex-col"><p className='text-sm  '><span className='text-[var(--color-0048D4)] font-medium'>{item.name}</span> <span className='text-[var(--color-757575)]'>{item.notification_string}</span></p>
                                                            <span className='text-[var(--color-757575)] text-[11px]'>
                                                                {dayjs(item.created_on).isSame(dayjs(), 'day')
                                                                    ? dayjs(item.created_on).format('hh:mm A')
                                                                    : dayjs(item.created_on).format('MMM D, YYYY, h:mm A')}
                                                            </span></div>
                                                    </div>
                                                </li>
                                            </>
                                        ))}

                                    </ul>
                                    <div className="flex items-center justify-end mt-[18px]" onClick={() => navigate(ROUTE_NOTIFICATION_LIST)}>
                                        <p className='text-sm tracking-[0.05px] me-[6px]'>View More</p> <KeyboardArrowRightIcon className='me-6' />
                                    </div>
                                </div>}

                            </div>}

                        <div className='common-dropdown bg-[var(--color-F5F5F5)] rounded-lg border border-[var(--color-EEE)] p-[6px] pl-4' onClick={() => setshowUser(!showUser)}>
                            <div className='extra-space '></div>
                            <div className="flex gap-4 items-center ">
                          
                                    <Avatar sx={{ height: "34px", width: "34px", border: "2px solid white" }} alt={loginUserName} src={logedUserProfilePic}>
                                        {loginUserName.charAt(0).toUpperCase()}
                                    </Avatar>
                      

                                <MoreVertOutlinedIcon />
                            </div>

                            <div className={`${showUser ? 'hidden' : ' dropdown-list rounded w-max right-[46px] top-[-23.5px] px-[10px] text-[var(--color-757575)] text-xs font-medium tracking-[0.05px] py-1'}  `}>
                                {loginUserName}
                            </div>

                            <div className={`${showUser ? 'block dropdown-list min-w-[126px] right-0 top-[50.5px] ' : 'hidden'}  `} ref={dropdownRef}>
                                <ul className="custom-scrollBar max-h-[250px] ">
                                    {(loginUserRole !== USER_ROLE_SUPERADMIN) &&
                                        <li
                                            className={`${" text-[var(--color-757575)]"} text-base font-medium tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                            onClick={() => navigate(ROUTE_PROFILE)}
                                        >
                                            My Profile
                                        </li>}
                                    <li
                                        className={`${" text-[var(--color-757575)]"} text-base font-medium tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                        onClick={() => handleLogout()}
                                    >
                                        Logout
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </nav>
        </header >
    )
}

export default React.memo(Navbar);