import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import sort from '../../assets/images/sortIcon.svg'
import sortDownArrow from '../../assets/images/sortDownArrow.svg'
import sortUpArrow from '../../assets/images/sortUpArrow.svg'
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import { GENDER_FEMALE, GENDER_MALE, GENDER_TRANSGENDER, NURSING_STAFF_SPACE, PATIENTS, P_ACT_ADD_EDIT, P_ACT_APPROVE_CREATE, P_ACT_DEACTIVATE_OR_ACTIVATE, RELATIVES, ROLE_ROLE_AND_PERMISSIONS, STATUE_ACTIVE, STATUE_INACTIVE, STATUE_NOTAPPROVED, USER_ROLE_CLIENT_ASSOCIATE, useHasActionPermission, useHasViewPermission } from "../../config/permissions";
import { useLocation } from "react-router-dom";
import { ROUTE_ASSOCIATE_USERLIST } from "../../config/routeConfig";
import { useAppSelector } from "../../helpers/hook";
import { loginPageSlices } from "../../pages/loginPage/loginPageSlice";
import { Avatar } from "@mui/material";


interface IProps {
    titles: any[];
    data: any[];
    sortedFieldName?: any;
    sortedOrder?: string;
    handleSort?: (sortDetail: any) => void;
    onClickEdit?: (obj: any) => void;
    onClickNameClick?: (obj: any) => void;
    onClickView?: (obj: any) => void;
    onResendEmail?: (obj: any) => void;
    onClickStatus?: (obj: any) => void;
    onClickApprove?: (obj: any) => void;
    parentPage: string;
    className?: string;
    setPermission: boolean;
    currentPage?: number;
    numRowsPerPage?: number;
    hasResendEmailPermission?: boolean;

}

const Table: React.FC<IProps> = (props) => {


    const location = useLocation();
    const { logedUsername, loginUserRole } = useAppSelector(loginPageSlices);


    //PERMISSION
    const checkEditPermission = useHasActionPermission(props.parentPage, P_ACT_ADD_EDIT);
    const checkApprovePermission = useHasActionPermission(props.parentPage, P_ACT_APPROVE_CREATE);
    const checkChangeStatusPermission = useHasActionPermission(props.parentPage, P_ACT_DEACTIVATE_OR_ACTIVATE);
    const checkViewPermission = useHasViewPermission(props.parentPage);

    const hasEditPermission = props.setPermission ? checkEditPermission : true;
    const hasApprovePermission = props.setPermission ? checkApprovePermission : true;
    const hasChangeStatusPermission = props.setPermission ? checkChangeStatusPermission : true;
    const hasViewPermission = props.setPermission ? checkViewPermission : true;

    const viewEditPermission = (value: string) => (location.pathname === ROUTE_ASSOCIATE_USERLIST ? (loginUserRole === USER_ROLE_CLIENT_ASSOCIATE ? logedUsername !== value : true) : true)





    const [sortField, setSortField] = useState(
        props?.sortedFieldName ? props.sortedFieldName : ""
    );
    const [sortOrder, setSortOrder] = useState(
        props?.sortedOrder ? props.sortedOrder : ""
    );


    const handleSortData = (name: string): void => {
        props.handleSort?.(name);
    };


    const handleSort = (e: any, title: any) => {

        if (sortField === title) {
            const so = sortOrder === "-" ? "+" : "-";
            setSortOrder(so);
            handleSortData(sortField + so);
        } else {
            setSortField(title);
            setSortOrder("");
            handleSortData(title + "+");
        }

    };



    // table head part

    const tableHeaders = (): any => {
        return (
            <thead >
                <tr>
                    {props.titles.map((title, index) => (
                        <th
                            key={index}
                            className={
                                (
                                    title.title === "actionMenu"
                                        ? " text-center" : title.type === "status"
                                            ? " min-w-[70px] " : title.type === "serial"
                                            && " min-w-[50px]  text-center"
                                ) + `  ${title.sortBy ? " cursor-pointer " : " "} border-b  bg-[var(--color-FAFAFA)] text-[13px] text-[var(--color-757575)] font-normal py-3 select-none`
                            }
                            onClick={(e) =>
                                title.sortBy && handleSort(e, title.sortTitle)
                            }
                        >
                            <div className={`${(title.type === "serial" || title.title === "actionMenu") ? 'justify-center ' : 'pr-3'} flex gap-3 items-center `} >

                                {title.display_title}
                                {title.sortBy &&
                                    (sortField === title.sortTitle ||
                                        props.sortedFieldName === title.sortTitle ? (

                                        <span>{(props.sortedFieldName === title.sortTitle) || (props.sortedFieldName === `${title.sortTitle}+`) ? <span><img src={sortDownArrow} alt="sortDownArrow" loading="lazy" decoding="async" /></span> : (props.sortedFieldName === `${title.sortTitle}-`) ? <span><img src={sortUpArrow} alt="sort" loading="lazy" decoding="async" /></span> : <span><img src={sort} alt="sort" loading="lazy" decoding="async" /></span>}</span>

                                    ) : (<span><img src={sort} alt="sort" loading="lazy" decoding="async" /></span>))}
                                {/* ) : (<span>&#8595;</span>))} */}

                            </div>
                        </th>
                    ))}
                </tr>
            </thead >
        );
    };

    // table body
    const tableBody = (): any => {
        return (
            <>
                <tbody>
                    {(!props.data || (props.data && props.data.length === 0)) && (
                        <>
                            <tr className=" text-center border-b text-sm text-[var(--color-2121210)]  font-normal">
                                <td colSpan={props.titles.length} className="py-[18px]">No Data</td>
                            </tr>
                        </>
                    )}
                    {props.data &&
                        props.data.map((data, indexOne) => (
                            <tr key={indexOne}>
                                {props.titles.map((title, indexTwo) => (
                                    <td
                                        className="border-b text-sm text-[var(--color-2121210)] py-[18px] font-normal align-top "
                                        key={indexTwo}
                                    >
                                        {title.type === "text" && (
                                            <div className="pr-3 flex items-center gap-2">
                                                {title.profile_pic && <>
                                                   
                                                        <Avatar className='h-8 w-8 ' alt={data[title.title]} src={data.profile_pic} >
                                                            {data[title.title]?.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                                                        </Avatar>
                                                  
                                                </>}
                                                {data[title.title]}
                                            </div>)}

                                        {title.type === "gender" && (
                                            <div className="pr-3">
                                                {
                                                    data[title.title] === GENDER_MALE ? <>Male</> :
                                                        data[title.title] === GENDER_FEMALE ? <>Female</> :
                                                            data[title.title] === GENDER_TRANSGENDER ? <>Transgender</> : <></>
                                                }
                                            </div>)}

                                        {title.type === "timesheetTime" && (
                                            <div className="pr-3">{data[title.title]} </div>)}

                                        {title.type === "date" && ((data[title.title] && data[title.title].length > 0 && data[title.title] !== null) &&
                                            <div className="pr-3">{dayjs(data[title.title]).format(`MM/DD/YYYY`)}</div>)}

                                        {title.type === "date_time" && (
                                            <div className="pr-3">{dayjs(data[title.title]).format(`MM/DD/YYYY, hh:mm A`)}</div>)}

                                        {title.type === "link" && (
                                            <div className="pr-3 cursor-pointer text-[var(--color-0048D4)] hover:font-medium flex items-center gap-2" onClick={() => props.onClickNameClick?.(data)}>

                                                {title.profile_pic && <>
                                                   
                                                        <Avatar className='h-8 w-8 ' alt={data[title.title]} src={data.profile_pic}  >
                                                            {data[title.title]?.charAt(0).toUpperCase()} {/* Display first letter of sender_name */}
                                                        </Avatar>
                                                
                                                </>}
                                                {data[title.title]}
                                            </div>)}

                                        {title.type === "serial" && (
                                            <div className="text-center">
                                                {(indexOne + 1) + ((props.currentPage ?? 1) - 1) * (props.numRowsPerPage ?? 1)}
                                            </div>
                                        )}

                                        {title.type === "mobile" && (
                                            <div className="pr-2">
                                                <PhoneInput
                                                    disabled={true}
                                                    buttonClass="hidden"
                                                    containerClass="border-none w-fit "
                                                    inputClass="border-none w-fit pl-0 cursor-text h-[18px]"
                                                    value={data[title.title]}
                                                    placeholder={""} />
                                            </div>
                                        )}

                                        {title.type === "status" && (

                                            <div className={`pr-2 `}>
                                                {data.active_status === STATUE_ACTIVE ?
                                                    <span className="text-[var(--color-success)]">Active</span> :
                                                    data.active_status === STATUE_INACTIVE ?
                                                        <span className="text-[var(--color-secondary)]">Inactive</span> :
                                                        <span className="text-[var(--color-danger)]">Not Approved</span>}
                                            </div>
                                        )}



                                        {title.type === "email" && (
                                            <div className="pr-3 flex flex-col gap-4 w-fit">
                                                <div> {data[title.title]}</div>
                                                {(!data.email_verified && props.hasResendEmailPermission && (data[title.title] && data[title.title].length > 0)) && <div className="flex justify-between gap-2">

                                                    <sup className="text-[var(--color-danger)] ">
                                                        Not verified
                                                    </sup>

                                                    <span></span>
                                                    {(data[title.title] && data[title.title]?.length > 0) &&
                                                        <sup
                                                            className="cursor-pointer text-[var(--color-0048D4)] hover:underline"
                                                            onClick={() => props.onResendEmail?.(data)}
                                                        >
                                                            Resend email
                                                        </sup>
                                                    }

                                                </div>}
                                            </div>)}

                                        {title.type === "multiStatus" && (
                                            <div className="flex flex-col gap-[18px]"> {
                                                data.approval_status !== undefined ?
                                                    (data.approval_status && (data.group === NURSING_STAFF_SPACE || data.group === PATIENTS || data.group === RELATIVES)) ?
                                                        data.facilities.map((item: any) =>
                                                            <span className={`pr-2 ${item.active ? 'text-[var(--color-success)]' : 'text-[var(--color-secondary)]'}`} >
                                                                {item.active ? 'Active' : 'Inactive'}
                                                            </span>) : (data.group === NURSING_STAFF_SPACE || data.group === PATIENTS || data.group === RELATIVES) ?
                                                            <span className="text-[var(--color-danger)]">Not Approved</span> : data.facilities.map((item: any) =>
                                                                <span className={`pr-2 ${item.active ? 'text-[var(--color-success)]' : 'text-[var(--color-secondary)]'}`} >
                                                                    {item.active ? 'Active' : 'Inactive'}
                                                                </span>
                                                            ) : data.facilities.map((item: any) =>
                                                                <span className={`pr-2 ${item.active ? 'text-[var(--color-success)]' : 'text-[var(--color-secondary)]'}`} >
                                                                    {item.active ? 'Active' : 'Inactive'}
                                                                </span>
                                                            )}
                                            </div>
                                        )}

                                        {title.type === "rowMultiData" && (
                                            <div className="pr-3 flex flex-col gap-[18px]">
                                                {data.facilities.map((item: any) => <span>{item.facility_name}</span>)}
                                            </div>)}


                                        {title.title === "actionMenu" && (

                                            (hasEditPermission || hasViewPermission || hasChangeStatusPermission || (data.approval_status === false && hasApprovePermission)) &&
                                            <>
                                                <div className='common-dropdown '>
                                                    <div className='extra-space '></div>
                                                    <div className='mx-auto'>
                                                        <MoreVertIcon className="text-[19px]" />
                                                    </div>
                                                    <div className={`dropdown-list w-fit py-3 px-5 top-6 inset-x-2/4 transform  translate-y-0 ${props.parentPage === ROLE_ROLE_AND_PERMISSIONS ? "translate-x-[-50%]" : " translate-x-[-81%] "}`}>
                                                        <ul className="">
                                                            {(title.type === "AED" && data.active_status === STATUE_NOTAPPROVED && hasApprovePermission) && (
                                                                <>
                                                                    <li
                                                                        key={"approve"}
                                                                        className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                        onClick={() => props.onClickApprove?.(data)}
                                                                    >
                                                                        Approve
                                                                    </li>
                                                                </>
                                                            )}
                                                            {(title.type === "ED" || title.type === "AED") && (
                                                                <>
                                                                    <li
                                                                        key={"view"}
                                                                        className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                        onClick={() => props.onClickView?.(data)}
                                                                    >
                                                                        View
                                                                    </li>
                                                                    {hasEditPermission && <li
                                                                        key={"edit"}
                                                                        className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                        onClick={() => props.onClickEdit?.(data)}
                                                                    >
                                                                        Edit
                                                                    </li>}

                                                                    {(hasChangeStatusPermission && data.active_status !== STATUE_NOTAPPROVED) && <li
                                                                        key={"Deactivate"}
                                                                        className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                        onClick={() => props.onClickStatus?.(data)}
                                                                    >
                                                                        {(data.active || data.active_status === STATUE_ACTIVE) ? <>Deactivate</> : <>Activate</>}
                                                                    </li>}


                                                                </>
                                                            )}


                                                        </ul>
                                                    </div>

                                                </div>
                                            </>

                                        )}

                                        {title.title === "multiActionMenu" && (
                                            <div className="flex flex-col gap-[18px]">
                                                {((data.approval_status !== undefined && data.approval_status === false) && (data.group === NURSING_STAFF_SPACE || data.group === PATIENTS || data.group === RELATIVES)) ?
                                                    (hasEditPermission || hasViewPermission || hasChangeStatusPermission || (data.approval_status === false && hasApprovePermission)) &&
                                                    <>
                                                        <div className='common-dropdown '>
                                                            <div className='extra-space '></div>
                                                            <div className='mx-auto mb-[9px]'>
                                                                <MoreVertIcon className="text-[19px]" />
                                                            </div>

                                                            <div className='dropdown-list w-fit py-3 px-5 top-6 inset-x-2/4 "transform translate-x-[-81%] translate-y-0'>
                                                                <ul className="">
                                                                    {(title.type === "AED" && data.approval_status === false && hasApprovePermission) && (
                                                                        <>
                                                                            <li
                                                                                key={"approve"}
                                                                                className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                                onClick={() => props.onClickApprove?.(data.facilities.map((item: any) => item)[0])}
                                                                            >
                                                                                Approve
                                                                            </li>
                                                                        </>
                                                                    )}
                                                                    {(title.type === "ED" || title.type === "AED") && (
                                                                        <>
                                                                            <li
                                                                                key={"view"}
                                                                                className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                                onClick={() => props.onClickView?.(data.facilities.map((item: any) => item)[0])}
                                                                            >
                                                                                View
                                                                            </li>
                                                                            {(hasEditPermission && viewEditPermission(data.email)) && <li
                                                                                key={"edit"}
                                                                                className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                                onClick={() => props.onClickEdit?.(data.facilities.map((item: any) => item)[0])}
                                                                            >
                                                                                Edit
                                                                            </li>}
                                                                        </>
                                                                    )}


                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </> :
                                                    data.facilities.map((item: any) =>
                                                        (hasEditPermission || hasViewPermission || hasChangeStatusPermission || (data.approval_status === false && hasApprovePermission)) &&
                                                        <>
                                                            <div className='common-dropdown '>
                                                                <div className='extra-space '></div>
                                                                <div className='mx-auto'>
                                                                    <MoreVertIcon className="text-[19px]" />
                                                                </div>

                                                                <div className='dropdown-list w-fit py-3 px-5 top-6 inset-x-2/4 "transform translate-x-[-81%] translate-y-0'>
                                                                    <ul className="">
                                                                        {(title.type === "ED" || title.type === "AED") && (
                                                                            <>
                                                                                <li
                                                                                    key={"view"}
                                                                                    className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                                    onClick={() => props.onClickView?.(item)}
                                                                                >
                                                                                    View
                                                                                </li>
                                                                                {(hasEditPermission && viewEditPermission(data.email)) && <li
                                                                                    key={"edit"}
                                                                                    className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                                    onClick={() => props.onClickEdit?.(item)}
                                                                                >
                                                                                    Edit
                                                                                </li>}
                                                                                {(hasChangeStatusPermission && viewEditPermission(data.email)) && <li
                                                                                    key={"Deactivate"}
                                                                                    className={` text-[var(--color-757575)] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer`}
                                                                                    onClick={() => props.onClickStatus?.(item)}
                                                                                >
                                                                                    {item.active ? <>Deactivate</> : <>Activate</>}
                                                                                </li>}
                                                                            </>
                                                                        )}


                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </>)}
                                            </div>
                                        )}

                                    </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
            </>
        );
    };

    const content = (): JSX.Element => {
        return (
            <table className={"bg-white w-full table-auto " + props.className} >
                {tableHeaders()}
                {tableBody()}
            </table>
        );
    };

    return (<>{content()}</>);
};

export default React.memo(Table);
