import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, postFormData, put } from "./apiService";
import { IPatientData, IPatientFamilyMember, IPatientState, IPatientStatus } from "../models/patientListInterfaces";
import { URL_ADD_PATIENT, URL_APPROVE_PATIENT, URL_EDIT_PATIENT, URL_PATIENT_FAMILY_MEMBER, URL_PATIENT_LIST, URL_REMOVE_IMAGE, URL_RESENT_MAIL, URL_STATUS_PATIENT, URL_UPDATE_PATIENT, URL_UPLOAD_IMAGE } from "../config/apiUrls";
import { IImageInput, IRemoveImage, IResentEmail } from "../models/commonInterfaces";

// to fetch all patient user
export const fetchPatientList = createAsyncThunk(
  'user/fetchPatientList',
  async (form: IPatientState, thunkAPI) => {
    try {
      const {  page, ...body } = form;
      let url = URL_PATIENT_LIST;
      if (page !== 0) {
        url = url + '?page=' + page
      }
      const response = post(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// to fetch patient family member
export const fetchUserFamilyMember = createAsyncThunk(
  'user/fetchUserFamilyMember',
  async (form: IPatientFamilyMember, thunkAPI) => {
    try {
      const {  facility, ...body } = form;
      const response = get(URL_PATIENT_FAMILY_MEMBER+`?facility=${facility}`, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// to fetch all patient user
export const fetchPatientListSearch = createAsyncThunk(
  'user/fetchPatientListSearch',
  async (form: IPatientState, thunkAPI) => {
    try {
      const {  page, ...body } = form;
      let url = URL_PATIENT_LIST;
      if (page !== 0) {
        url = url + '?page=' + page
      }
      const response = post(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to add patient user
export const addPatientData = createAsyncThunk(
  'user/addPatientData',
  async (form: IPatientData, thunkAPI) => {
    try {
      const response = post(URL_ADD_PATIENT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update patient user user
export const updatePatientData = createAsyncThunk(
  'user/updatePatientData',
  async (form: IPatientData, thunkAPI) => {
    try {
      const { uuid, ...body } = form;
      const url = URL_UPDATE_PATIENT + uuid;
      const response = put(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// to get singal patient user user details
export const editPatientData = createAsyncThunk(
  'user/editPatientData',
  async (form: IPatientState, thunkAPI) => {
    try {
      const {  uuid, ...body } = form;
      const url = URL_EDIT_PATIENT + uuid;
      const response = get(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update status on patient user list
export const updatePatientUserStatus = createAsyncThunk(
  'user/updatePatientUserStatus',
  async (form: IPatientStatus, thunkAPI) => {
    try {
      const response = put(URL_STATUS_PATIENT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to Approvestatus on patient user
export const patientUserApprove = createAsyncThunk(
  'user/patientUserApprove',
  async (form: IPatientStatus, thunkAPI) => {
    try {
      const response = put(URL_APPROVE_PATIENT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resentVerificationMail = createAsyncThunk(
  'user/resentVerificationMail',
  async (form: IResentEmail, thunkAPI) => {
    try {
      const response = post(URL_RESENT_MAIL, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addPatientImageInput = createAsyncThunk(
  'user/addPatientImageInput',
  async (form: IImageInput, thunkAPI) => {
    try {
      const response = postFormData(URL_UPLOAD_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removePatientImageInput = createAsyncThunk(
  'user/removePatientImageInput',
  async (form: IRemoveImage, thunkAPI) => {
    try {
      const response = post(URL_REMOVE_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);