import React, { useEffect, useState } from 'react'
import RegistrationNavbar from '../../components/navbar/RegistrationNavbar'
import { useNavigate } from 'react-router-dom'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
import RegistrationAside from '../../components/sidebar/RegistrationAside'
import RegistrationSuccess from '../../components/notification/RegistrationSuccess'
import SelectField from '../../components/inputFeild/SelectField'
import { RELATIVES } from '../../config/permissions'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { CommonSlices } from '../common/commonSlice'
import { fetchFacilityDropdowns } from '../../services/commonServices'
import {
  FamilyRegistrationSlices,
  resetContactDetails
} from './familyMemberRegistrationSlice'
import { addFamilyReg } from '../../services/familyRegServices'
import { IFamilyRegData } from '../../models/FamilyRegInterface'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ReCAPTCHA from 'react-google-recaptcha'
import { REACT_APP_RECAPTCHA } from '../../config/urlConfig'
import WebFooter from '../../components/footer/WebFooter'

interface FormState {
  firstName: string
  middleName: string
  lastName: string
  facility: string
  isAlertOpen: boolean
  termsAndC: boolean
  gender: string
  respMessage: string
  email: string
}

const FamilyMemberRegistration = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const recaptcha: string | undefined = REACT_APP_RECAPTCHA
  const { facilityDatas } = useAppSelector(CommonSlices)
  const { loading } = useAppSelector(
    FamilyRegistrationSlices
  )

  const initialFormState: FormState = {
      firstName: '',
      middleName: '',
      lastName: '',
      facility: '',
      isAlertOpen: false,
      termsAndC: false,
      gender: '',
      respMessage: '',
      email: ''
  }

  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [cathpcha, setcathpcha] = useState<boolean>(false)

  useEffect(() => {
    dispatch(resetContactDetails)
    dispatch(fetchFacilityDropdowns())
    handleResetData()
  }, [])

  useEffect(() => {
    if (loading === 'success') {
      const msgText =
        'Dear ' +
        (
          formState.lastName +
          ' ' +
          formState.firstName +
          ' ' +
          formState.middleName
        ).trim() +
        ', Your registration process will be reviewed by CFP.'

      // if (
      //   contactEmail &&
      //   contactEmail.trim().length > 1 &&
      //   contactPhone &&
      //   contactPhone.trim().length > 1
      // ) {
      //   msgText +=
      //     ' please send your documents to ' +
      //     contactEmail +
      //     ' or call ' +
      //     contactPhone +
      //     '.'
      // } else if (contactEmail && contactEmail.trim().length > 1) {
      //   msgText += ' please send your documents to ' + contactEmail + '.'
      // } else if (contactPhone && contactPhone.trim().length > 1) {
      //   msgText += ' please call ' + contactPhone + '.'
      // } else {
      //   msgText += ' please contact the administrator.'
      // }

      setFormState({
        ...formState,
        isAlertOpen: true,
        respMessage: msgText
      })
    }
  }, [loading])

  const handleFamilyRegistration = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (
      formState.firstName.length > 0 &&
      formState.lastName.length > 0 &&
      formState.facility.length > 0
    ) {
      const req: IFamilyRegData = {
        type: 2,
        facility: Number(formState.facility),
        first_name: formState.firstName,
        middle_name: formState.middleName,
        last_name: formState.lastName,
        gender: Number(formState.gender),email:formState.email
      }
      //for add client
      dispatch(addFamilyReg(req))
    } else {
      setNotification(
        message.CFPCareConnect,
        message.fillOutAllMandatoryFields,
        false,
        'failed',
        'handleSaveFamilyData'
      )
    }
  }

  //reset data
  const handleResetData = () => {
    setFormState(initialFormState)
  }

  function onChangeCapthcha (value: any) {
    if (value !== null && value.length > 0) {
      setcathpcha(true)
    }
  }

  const onClickDone = () => {
    handleResetData()
    dispatch(resetContactDetails)
    navigate('/')
  }
  return (
    <>
      <RegistrationNavbar
        onClick={() => {
          handleResetData()
          navigate('/')
        }}
      />
      <section className='flex flex-col justify-center items-center w-full px-[37px] lg:px-[62px] '>
        <div className='mt-[154px] flex w-full max-w-[1436px]  gap-8'>
          <RegistrationAside parent='family' />
          <form className='w-full ' onSubmit={e => handleFamilyRegistration(e)}>
            <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px]'>
              Personal Information
            </h2>
            <hr className='border-[var(--color-E0E0E0)] mt-1' />
            <div className='flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap'>
              <TextField
                label=' First Name'
                required={true}
                value={formState.firstName}
                onChange={e =>
                  setFormState({
                    ...formState,
                    isAlertOpen: false,
                    firstName: e.target.value
                  })
                }
                placeholder=' First Name'
              />
              <TextField
                label=' Middle Name'
                value={formState.middleName}
                onChange={e =>
                  setFormState({
                    ...formState,
                    isAlertOpen: false,
                    middleName: e.target.value
                  })
                }
                placeholder=' Middle Name'
              />
            </div>
            <div className='flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap'>
              <TextField
                label=' Last Name'
                required={true}
                value={formState.lastName}
                onChange={e =>
                  setFormState({
                    ...formState,
                    isAlertOpen: false,
                    lastName: e.target.value
                  })
                }
                placeholder=' Last Name'
              />
              <SelectField
                label='Gender'
                placeHolder={true}
                value={formState.gender}
                onChange={e =>
                  setFormState({
                    ...formState,
                    isAlertOpen: false,
                    gender: e.target.value
                  })
                }
                options={[
                  { name: 'Select Gender', id: '' },
                  { name: 'Male', id: '1' },
                  { name: 'Female', id: '2' },
                  { name: 'Other', id: '3' }
                ]}
              />
            </div>
            <div className='flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap'>
              <SelectField
                label='Select Facility'
                search={true}
                placeHolder={true}
                required={true}
                onChange={e =>
                  setFormState({
                    ...formState,
                    isAlertOpen: false,
                    facility: e.target.value
                  })
                }
                value={formState.facility}
                options={[
                  { name: 'Select Facility', id: '', isChecked: false },
                  ...facilityDatas
                ]}
              />
              <TextField
                label='Email'
                value={formState.email}
                type='email'
                onChange={e => setFormState({ ...formState, email: e.target.value })}
                placeholder='Email'
              />
            </div>

            <div className='flex gap-4 mt-9 items-center'>
              <input
                type='checkbox'
                name=''
                id=''
                checked={formState.termsAndC}
                onChange={e =>
                  setFormState({ ...formState, termsAndC: e.target.checked })
                }
              />
              I agree the terms & conditions of the use of this website
            </div>
            <hr className='border-[var(--color-E0E0E0)] mt-20' />
            <div className='flex justify-between items-center '>
              <ReCAPTCHA
                sitekey={recaptcha ? recaptcha : ''}
                onChange={onChangeCapthcha}
              />
              <div className='flex gap-4 justify-end items-end mt-9 mb-[52px]'>
                <ButtonType
                  type='webCardbtn'
                  buttonLabel='Reset'
                  onClick={handleResetData}
                  className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]'
                />

                {formState.termsAndC && cathpcha && (
                  <ButtonType
                    type='webCardbtn'
                    buttonLabel='Register'
                    buttonType='submit'
                    className='px-12 !important'
                  />
                )}
              </div>
            </div>
          </form>
        </div>
        <RegistrationSuccess
          parent={RELATIVES}
          type='registrationSuccess'
          buttonLabelOne='Done'
          open={
            formState.firstName.length > 1 && formState.isAlertOpen === true
          }
          respMessage={formState.respMessage}
          onClickOne={() => {
            onClickDone()
          }}
          onClose={() => setFormState({ ...formState, isAlertOpen: false })}
        />
      </section>
      <WebFooter />
    </>
  )
}

export default FamilyMemberRegistration
