import React, { ChangeEvent, useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType';
import SignatureCanvas from 'react-signature-canvas';
import TextField from '../../components/inputFeild/TextFeild';
import SelectField from '../../components/inputFeild/SelectField';
import ImageInput from '../../components/inputFeild/ImageInput';
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { CommonSlices } from '../common/commonSlice';
import { changeLogedUserName, changeLogedUserProfilePic, loginPageSlices } from '../loginPage/loginPageSlice';
import { DEFAULT_COUNTRY, DEFAULT_COUNTRY_US, LOGO_UPLOAD_CLIENT, LOGO_UPLOAD_LOGO, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, LOGO_UPLOAD_SIGN, SELECT_TYPE_PATIENTS, USER_ROLE_CLIENT_ADMIN, USER_ROLE_CLIENT_ASSOCIATE, USER_ROLE_NURSING_STAFF, USER_ROLE_PATIENTS, USER_ROLE_PROVIDER, USER_ROLE_RELATIVES } from '../../config/permissions';
import MultiSelectField from '../../components/inputFeild/MultiSelectField';
import { filterDigitsOnly } from '../../helpers/validators';
import { IPatientData, IPatientState } from '../../models/patientListInterfaces';
import { addPatientImageInput, editPatientData, removePatientImageInput, updatePatientData } from '../../services/patientListServices';
import { fetchRelationshipData } from '../../services/commonServices';
import { PatientListSlices } from '../patientList/patientListSlice';
import { IImageInput, IRemoveImage } from '../../models/commonInterfaces';
import { message } from '../../utilities/locals/messages';
import { setNotification } from '../../components/notification/ToastNotification';
import { INursingStaffData, INursingStaffState } from '../../models/nursingStaffListInterfaces';
import { addNursingStaffImageInput, editNursingStaffData, removeNursingStaffImageInput, updateNursingStaffData } from '../../services/nursingStaffListServices';
import { NursingStaffSlices } from '../nursingStaffList/nursingStaffListSlice';
import { IAssociateData, IAssociateState } from '../../models/associateListInterfaces';
import { addAssociateImageInput, editAssociateData, removeAssociateImageInput, updateAssociateData } from '../../services/associateServices';
import { ClientListSlices } from '../clientList/clientListSlice';
import { AssociateListSlices } from '../associateUserList/associateListSlice';
import { ProviderListSlices } from '../providerList/providerListSlice';
import { IClientData, IClientList } from '../../models/clientListInterfaces';
import { addImageInput, addImageProfileInput, editClientData, removeImageInput, removeImageProfileInput, updateClientData } from '../../services/clientListServices';
import { IProviderData, IProviderState } from '../../models/providerListInterfaces';
import { addProviderImageInput, addProviderSignInput, editProviderData, removeProviderImageInput, removeProviderSignInput, updateProviderData } from '../../services/providerServices';
import { EMAIL_NOTIFICATIONS_NO, EMAIL_NOTIFICATIONS_YES, GENDER_SEL_OPTIONS, USER_TYPE_PATIENT_ID, USER_TYPE_RELATIVE_ID } from '../../config/globalValues'; // USER_TYPE_SEL_OPTIONS - NOT USED NOW - USER CANNOT SWITCH BETWEEN 'Patient' and 'Relative' - 21Aug2024

const MyProfile = () => {
    const dispatch = useAppDispatch();
    const { facilityData, relationshipData } = useAppSelector(CommonSlices);
    const { loginUserRole, unid, token, uuid, client } = useAppSelector(loginPageSlices);
    const nursingStaffData = useAppSelector(NursingStaffSlices);
    const patientData = useAppSelector(PatientListSlices);
    const clientDetails = useAppSelector(ClientListSlices);
    const associateUserData = useAppSelector(AssociateListSlices);
    const providerData = useAppSelector(ProviderListSlices);

    const { stateData } = useAppSelector(CommonSlices);

    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [logoImage, setLogoImage] = useState<string>('');

    const [imageProfileId, setImageProfileId] = useState<number | null>(null);

    const [gender, setGender] = useState<string>('');
    const [patientGender, setPatientGender] = useState<string>('');
    const [patientDOB, setPatientDOB] = useState<string>('');
    const [facility, setFacility] = useState<string>('');
    const [multifacility, setMultiFacility] = useState<number[]>([]);
    const [email, setEmail] = useState<string>('');
    const [mobile, setMobile] = useState('');
    const [alternativeMobile, setAlternativeMobile] = useState('');
    const [notes, setNotes] = useState<string>('');
    const [emailNotification, setEmailNotification] = useState<string>(EMAIL_NOTIFICATIONS_NO);
    const [selectType, setSelectType] = useState<string>(USER_TYPE_PATIENT_ID);
    const [relationship, setRelationship] = useState<string>('1');
    const [patientFirstName, setPatientFirstName] = useState<string>('');
    const [patientMiddleName, setPatientMiddleName] = useState<string>('');
    const [patientLastName, setPatientLastName] = useState<string>('');
    const [allergies, setAllergies] = useState<string>('');

    const [clientName, setClientName] = useState<string>('');
    const [streetOne, setStreetOne] = useState<string>('');
    const [streetTwo, setStreetTwo] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zipCode, setZipCode] = useState<string>('');
    const [primaryContactFirstName, setPrimaryContactFirstName] = useState<string>('');
    const [primaryContactMiddleName, setPrimaryContactMiddleName] = useState<string>('');
    const [primaryContactLastName, setPrimaryContactLastName] = useState<string>('');
    const [primaryMobile, setPrimaryMobile] = useState<string>('');

    const [signImage, setsignImage] = useState<string>('');
    const [signImageId, setSignImageId] = useState<number | null>(null);
    const [sign, setSign] = useState<any>()

    useEffect(() => {
        if (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) {
            const req: IPatientState = {
                unid, token, uuid
            };
            dispatch(editPatientData(req));
            dispatch(fetchRelationshipData({ token, unid }));
        } else if (loginUserRole === USER_ROLE_NURSING_STAFF) {
            const req: INursingStaffState = {
                unid, token, uuid
            };
            dispatch(editNursingStaffData(req));
        } else if (loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) {
            const req: IAssociateState = {
                unid, token, uuid
            };
            dispatch(editAssociateData(req));
        } else if (loginUserRole === USER_ROLE_CLIENT_ADMIN) {
            const req: IClientList = {
                unid, token, uuid
            };
            dispatch(editClientData(req));

        } else if (loginUserRole === USER_ROLE_PROVIDER) {
            const req: IProviderState = {
                unid, token, uuid
            };
            dispatch(editProviderData(req));
        }

    }, [])

    useEffect(() => {
        setData();
    }, [
        patientData.patientUser,
        nursingStaffData.nursingStaff,
        associateUserData.associateUser,
        clientDetails.clientData,
        providerData.providerUser,
    ]);

    useEffect(() => {
        if (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) {
            setImageProId(patientData.imageId);
            setProfileImage(patientData.uploadedImg);
        } else if (loginUserRole === USER_ROLE_NURSING_STAFF) {
            setImageProId(nursingStaffData.imageId);
            setProfileImage(nursingStaffData.uploadedImg);
        } else if (loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) {
            setImageProId(associateUserData.imageId);
            setProfileImage(associateUserData.uploadedImg);
        } else if (loginUserRole === USER_ROLE_CLIENT_ADMIN) {
            setImageProId(clientDetails.imageId);
            setLogoImage(clientDetails.uploadedImg);
        } else if (loginUserRole === USER_ROLE_PROVIDER) {
            setImageProId(providerData.imageId);
            setProfileImage(providerData.uploadedImg);
        }
    }, [
        patientData.imageId,
        patientData.uploadedImg,
        nursingStaffData.imageId,
        nursingStaffData.uploadedImg,
        associateUserData.imageId,
        associateUserData.uploadedImg,
        clientDetails.imageId,
        clientDetails.uploadedImg,
        providerData.imageId,
        providerData.uploadedImg,
    ])

    useEffect(() => {
        setsignImage(providerData.uploadedSignImg);
        setSignImageId(providerData.imageSignId);
    }, [
        providerData.imageSignId,
        providerData.uploadedSignImg]);


    // useEffect(() => {
    //     setsignImage(clientDetails.uploadedSignImg);
    // }, [clientDetails.uploadedSignImg])

    // useEffect(() => {
    //     setSignImageId(clientDetails.imageSignId);
    // }, [clientDetails.imageSignId])

    useEffect(() => {
        setImageProfileId(clientDetails.imgProfileId);
        setProfileImage(clientDetails.uploadedProfileImg);
    }, [clientDetails.uploadedProfileImg, clientDetails.imgProfileId])




    const setData = () => {
        let userDetails: any = {};

        switch (loginUserRole) {
            case USER_ROLE_PATIENTS:
            case USER_ROLE_RELATIVES:
                userDetails = patientData.patientUser.length > 0 ? patientData.patientUser[0] : {};
                break;
            case USER_ROLE_NURSING_STAFF:
                userDetails = nursingStaffData.nursingStaff.length > 0 ? nursingStaffData.nursingStaff[0] : {};
                break;
            case USER_ROLE_CLIENT_ASSOCIATE:
                userDetails = associateUserData.associateUser.length > 0 ? associateUserData.associateUser[0] : {};
                break;
            case USER_ROLE_CLIENT_ADMIN:
                userDetails = clientDetails.clientData.length > 0 ? clientDetails.clientData[0] : {};
                break;
            case USER_ROLE_PROVIDER:
                userDetails = providerData.providerUser.length > 0 ? providerData.providerUser[0] : {};
                break;
            default:
                return;
        }

        if (Object.keys(userDetails).length > 0) {
            
            setFirstName(userDetails.first_name ? userDetails.first_name : '');
            setMiddleName(userDetails.middle_name ? userDetails.middle_name : '');
            setLastName(userDetails.last_name ? userDetails.last_name : '');
            setGender(userDetails.gender ? userDetails.gender.toString() : '');
            setFacility(userDetails.facility ? userDetails.facility.toString() : '');
            setEmail(userDetails.email ? userDetails.email : '');
            setMobile(userDetails.mobile ? userDetails.mobile : '');
            setAlternativeMobile(userDetails.alternate_mobile !== null ? userDetails.alternate_mobile : '');
            setProfileImage(userDetails.profile_pic_url !== null ? userDetails.profile_pic_url : '');
            setImageProId(userDetails.profile_pic !== null ? userDetails.profile_pic : null)
            setNotes(userDetails.notes ? userDetails.notes : '');
            if (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) {
                setEmailNotification(userDetails.notification ? userDetails.notification.toString() : EMAIL_NOTIFICATIONS_NO);
            }else{
                setEmailNotification(userDetails.notifications ? userDetails.notifications.toString() : EMAIL_NOTIFICATIONS_NO);                
            }

            setPatientFirstName(userDetails.patient_first_name ? userDetails.patient_first_name : '');
            setPatientMiddleName(userDetails.patient_middle_name ? userDetails.patient_middle_name : '');
            setPatientLastName(userDetails.patient_last_name ? userDetails.patient_last_name : '');
            setPatientGender(userDetails.patient_gender ? userDetails.patient_gender.toString() : '');
            setPatientDOB(userDetails.patient_dob ? userDetails.patient_dob : '');
            setAllergies(userDetails.allergies ? userDetails.allergies : '');
            setRelationship(userDetails.relationship ? userDetails.relationship.toString() : '')
            setSelectType(userDetails.patient_group === SELECT_TYPE_PATIENTS ? USER_TYPE_PATIENT_ID : USER_TYPE_RELATIVE_ID);

            if ((loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) || (loginUserRole === USER_ROLE_NURSING_STAFF) || (loginUserRole === USER_ROLE_PROVIDER)) {
                setMultiFacility(userDetails.facility.length > 0 ? userDetails.facility : []);
            }
            if (loginUserRole === USER_ROLE_PROVIDER
                // || loginUserRole === USER_ROLE_CLIENT_ADMIN
            ) {
                setsignImage(userDetails.sign_url);
                setSignImageId(userDetails.sign);
            }
            if (loginUserRole === USER_ROLE_CLIENT_ADMIN) {
                setClientName(userDetails.name);
                setStreetOne(userDetails.location.street1);
                setStreetTwo(userDetails.location.street2);
                setCity(userDetails.location.city);
                setState(userDetails.location.state.toString());
                setZipCode(userDetails.location.zip_code);
                setPrimaryContactFirstName(userDetails.user.first_name);
                setPrimaryContactMiddleName(userDetails.user.middle_name);
                setPrimaryContactLastName(userDetails.user.last_name);
                setPrimaryMobile(userDetails.user.mobile);
                setEmail(userDetails.user.email);
                setLogoImage(userDetails.logo_url ? userDetails.logo_url : '');
                setImageProId(userDetails.logo)
                setImageProfileId(userDetails.profile_pic)
                setEmailNotification(userDetails.notifications ? userDetails.notifications.toString() : EMAIL_NOTIFICATIONS_NO);
            }
        }

    };

    const handleSaveProfileData = (e: any) => {
        e.preventDefault();
        if (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) {
            e.preventDefault();
            if (firstName.length > 0 && lastName.length > 0 && facility.length > 0) {
                if (mobile.length < 2 || mobile.length > 6) {

                    const req: IPatientData = {
                        unid,
                        token,
                        uuid: uuid,
                        client: client,
                        type: Number(selectType),
                        facility: Number(facility),
                        gender: Number(gender),
                        profile_pic: imageProId,
                        sign: null,
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        mobile: mobile,
                        alternate_mobile: alternativeMobile,
                        notes: notes,
                        notification: Number(emailNotification),
                        patient_first_name: patientFirstName,
                        patient_middle_name: patientMiddleName,
                        patient_last_name: patientLastName,
                        patient_gender: Number(patientGender),
                        patient_dob: patientDOB,
                        allergy: allergies,
                        relationship: Number(relationship),
                    }
                    //for update client
                    dispatch(updatePatientData(req));
                    dispatch(changeLogedUserProfilePic(imageProId !== null ? profileImage : ''));
                    dispatch(changeLogedUserName(`${lastName !== "" ? lastName : ''} ${firstName !== "" ? (lastName !== "") ? ', ' + firstName : firstName : ''}`));



                } else {
                    setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSavePatientData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSavePatientData');
            }
        } else if (loginUserRole === USER_ROLE_NURSING_STAFF) {
            if (firstName.length > 0 && lastName.length > 0 && multifacility.length > 0 && email.length) {
                if (mobile.length < 2 || mobile.length > 6) {

                    const req: INursingStaffData = {
                        unid,
                        token,
                        uuid: uuid,
                        client: client,
                        facility: multifacility,
                        gender: Number(gender),
                        profile_pic: imageProId,
                        sign: null,
                        sign_base: '',
                        notifications: Number(emailNotification),
                        user_info: {
                            first_name: firstName,
                            middle_name: middleName,
                            last_name: lastName,
                            email: email,
                            mobile: mobile,
                            alternate_mobile: alternativeMobile
                        },
                    }
                    dispatch(updateNursingStaffData(req));
                    dispatch(changeLogedUserProfilePic(imageProId !== null ? profileImage : ''));
                    dispatch(changeLogedUserName(`${lastName !== "" ? lastName : ''} ${firstName !== "" ? (lastName !== "") ? ', ' + firstName : firstName : ''}`));

                } else {
                    setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveNursingStaffData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveNursingStaffData');
            }

        } else if (loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) {
            if (firstName.length > 0 && lastName.length > 0 && multifacility.length > 0 && email.length) {
                if (mobile.length < 2 || mobile.length > 6) {

                    const req: IAssociateData = {
                        unid,
                        token,
                        uuid: uuid,
                        client: client,
                        facility: multifacility,
                        gender: Number(gender),
                        profile_pic: imageProId,
                        user_info: {
                            first_name: firstName,
                            middle_name: middleName,
                            last_name: lastName,
                            email: email,
                            mobile: mobile,
                            alternate_mobile: alternativeMobile
                        },
                        notes: notes,
                        sign: null,
                        sign_base: '',
                        notifications: Number(emailNotification)
                    }
                    //for update client
                    dispatch(updateAssociateData(req));
                    dispatch(changeLogedUserProfilePic(imageProId !== null ? profileImage : ''));
                    dispatch(changeLogedUserName(`${lastName !== "" ? lastName : ''} ${firstName !== "" ? (lastName !== "") ? ', ' + firstName : firstName : ''}`));

                } else {
                    setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveAssociateData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveAssociateData');
            }
        } else if (loginUserRole === USER_ROLE_CLIENT_ADMIN) {
            if (clientName.length > 0 && streetOne.length > 0 && city.length > 0 && state.length > 0 && zipCode.length > 0 && primaryContactFirstName.length > 0 && primaryContactLastName.length > 0 && email.length) {
                if (mobile.length < 2 || mobile.length > 6) {

                    const req: IClientData = {
                        unid,
                        token,
                        uuid: uuid,
                        client: {
                            name: clientName,
                            mobile: mobile,
                            alternate_phone: alternativeMobile,
                            logo: imageProId
                        },
                        location: {
                            street1: streetOne,
                            street2: streetTwo,
                            city: city,
                            state: Number(state),
                            country: DEFAULT_COUNTRY,
                            zip_code: zipCode,
                        },
                        user_info: {
                            first_name: primaryContactFirstName,
                            middle_name: primaryContactMiddleName,
                            last_name: primaryContactLastName,
                            email: email,
                            profile_pic: imageProfileId,
                            mobile: primaryMobile,
                            notifications: Number(emailNotification),
                            // sign: signImage === '' ? null : signImageId,
                            // sign_base: signImage === '' ? (loginUserRole === USER_ROLE_CLIENT_ADMIN && sign.isEmpty()) ? "" : loginUserRole === USER_ROLE_CLIENT_ADMIN ? sign.getTrimmedCanvas().toDataURL('image/png') : '' : '',
                        }
                    }
                    dispatch(updateClientData(req));
                    dispatch(changeLogedUserProfilePic(imageProId !== null ? profileImage : ''));
                    dispatch(changeLogedUserName(`${primaryContactLastName !== "" ? primaryContactLastName : ''} ${primaryContactFirstName !== "" ? (primaryContactLastName !== "") ? ', ' + primaryContactFirstName : primaryContactFirstName : ''}`));


                } else {
                    setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveClientData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveClientData');
            }

        } else if (loginUserRole === USER_ROLE_PROVIDER) {
            if (firstName.length > 0 && lastName.length > 0 && multifacility.length > 0 && email.length > 0) {
                if (mobile.length < 2 || mobile.length > 6) {

                    const req: IProviderData = {
                        unid,
                        token,
                        uuid: uuid,
                        client: client,
                        facility: multifacility,
                        gender: Number(gender),
                        profile_pic: imageProId,
                        sign: signImage === '' ? null : signImageId,
                        sign_base: signImage === '' ? sign.isEmpty() ? "" : sign.getTrimmedCanvas().toDataURL('image/png') : '',
                        notifications: Number(emailNotification),
                        user_info: {
                            first_name: firstName,
                            middle_name: middleName,
                            last_name: lastName,
                            email: email,
                            mobile: mobile,
                            alternate_mobile: alternativeMobile
                        },
                    }
                    //for update client
                    dispatch(updateProviderData(req));
                    dispatch(changeLogedUserProfilePic(imageProId !== null ? profileImage : ''));
                    dispatch(changeLogedUserName(`${lastName !== "" ? lastName : ''} ${firstName !== "" ? (lastName !== "") ? ', ' + firstName : firstName : ''}`));


                } else {
                    setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveProviderData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveProviderData');
            }
        }
    }



    // for add profile image
    const handleAddImage = (selectedFile: File) => {
        let addImageAction;

        switch (loginUserRole) {
            case USER_ROLE_PATIENTS:
            case USER_ROLE_RELATIVES:
                addImageAction = addPatientImageInput;
                break;
            case USER_ROLE_NURSING_STAFF:
                addImageAction = addNursingStaffImageInput;
                break;
            case USER_ROLE_CLIENT_ASSOCIATE:
                addImageAction = addAssociateImageInput;
                break;
            case USER_ROLE_PROVIDER:
                addImageAction = addProviderImageInput;
                break;
            case USER_ROLE_CLIENT_ADMIN:
                addImageAction = addImageInput;
                break;
            default:
                return;
        }

        const req: IImageInput = {
            token,
            unid,
            image: selectedFile,
            type: loginUserRole === USER_ROLE_CLIENT_ADMIN ? LOGO_UPLOAD_CLIENT : LOGO_UPLOAD_OTHERS,
            image_type: loginUserRole === USER_ROLE_CLIENT_ADMIN ? LOGO_UPLOAD_LOGO : LOGO_UPLOAD_PROFILE_PIC,
        };

        dispatch(addImageAction(req));
    };

    //remove profile image

    const handleRemoveImage = () => {
        let removeImageAction;

        switch (loginUserRole) {
            case USER_ROLE_PATIENTS:
            case USER_ROLE_RELATIVES:
                removeImageAction = removePatientImageInput;
                break;
            case USER_ROLE_NURSING_STAFF:
                removeImageAction = removeNursingStaffImageInput;
                break;
            case USER_ROLE_CLIENT_ASSOCIATE:
                removeImageAction = removeAssociateImageInput;
                break;
            case USER_ROLE_CLIENT_ADMIN:
                removeImageAction = removeImageInput;
                break;
            case USER_ROLE_PROVIDER:
                removeImageAction = removeProviderImageInput;
                break;
            default:
                return;
        }

        if (imageProId && imageProId !== null) {
            const req: IRemoveImage = {
                token, unid, fid: imageProId
            };
            dispatch(removeImageAction(req));
        }

        setImageProId(null);
    };

    //for reset all value in modal
    const handleResetProfile = () => {
        setData();
    }

    //for add profile image
    const handleAddSign = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];

        if (selectedFile) {
            const req: IImageInput = {
                token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_SIGN
            }
            // if (loginUserRole === USER_ROLE_CLIENT_ADMIN) {
            //     dispatch(addClientAdminSignInput(req));
            // } else {
            dispatch(addProviderSignInput(req));
            // }
        }
        e.target.value = '';
    }

    //remove sign image
    const handleRemoveSign = () => {
        if (signImage === '') {
            sign.clear();
        }
        setsignImage('');
        if (signImageId !== null) {
            const req: IRemoveImage = {
                token, unid, fid: signImageId
            }
            // if (loginUserRole === USER_ROLE_CLIENT_ADMIN) {
            //     dispatch(removeClientAdminSignInput(req));
            // } else {
            dispatch(removeProviderSignInput(req));
            // }

        }
    }

    //handle remove profile picture in client admin
    const handleAddProfileImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addImageProfileInput(req));
    }
    const handleRemoveProfielImage = () => {
        if (imageProfileId && imageProfileId !== null) {

            const req: IRemoveImage = {
                token, unid, fid: imageProfileId
            }
            dispatch(removeImageProfileInput(req));
        }
    }

    return (
        <div className='w-full p-10'>
            <form typeof='submit' onSubmit={e => handleSaveProfileData(e)} className=''>
                <div className="px-9">

                    {(loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) && <>
                        {/* <div className="flex gap-8 w-full  flex-wrap md:flex-nowrap">
                            <SelectField
                                value={selectType}
                                onChange={(e) => setSelectType(e.target.value)}
                                label='Select Type'
                                placeHolder={false}
                                options={USER_TYPE_SEL_OPTIONS}
                            />
                            <div className="w-full"> &nbsp;</div>
                        </div> */}

                        {selectType === USER_TYPE_RELATIVE_ID ? <>
                            <div className=" title-border-b ">
                                Relative Information
                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' First Name'
                                    required={true}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder=' First Name' />
                                <TextField
                                    label=' Middle Name'
                                    value={middleName}
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    placeholder=' Middle Name' />
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Last Name'
                                    required={true}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder=' Last Name' />
                                <SelectField
                                    label='Gender'
                                    placeHolder={true}
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    options={GENDER_SEL_OPTIONS}
                                />
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <SelectField
                                    label='Select Facility'
                                    placeHolder={true}
                                    required={true}
                                    onChange={(e) => setFacility(e.target.value)}
                                    value={facility}
                                    options={[
                                        { name: 'Select Facility', id: '', isChecked: false },
                                        ...facilityData
                                    ]}
                                />
                                <div className="w-full"> &nbsp;</div>
                            </div>
                        </> : <>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label=' First Name'
                                        required={true}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder=' First Name' />
                                    <TextField
                                        label=' Middle Name'
                                        value={middleName}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        placeholder=' Middle Name' />
                                </div>
                                <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Last Name'
                                    required={true}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder=' Last Name' />
                                <SelectField
                                    label='Select Gender'
                                    placeHolder={true}
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    options={GENDER_SEL_OPTIONS}
                                />
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label='Patient DOB '
                                    required={true}
                                    type='date'
                                    value={patientDOB}
                                    onChange={(e) => setPatientDOB(e.target.value)}
                                    placeholder='Patient DOB ' />
                                <SelectField
                                    label='Select Facility'
                                    placeHolder={true}
                                    required={true}
                                    onChange={(e) => setFacility(e.target.value)}
                                    value={facility}
                                    options={[
                                        { name: 'Select Facility', id: '', isChecked: false },
                                        ...facilityData
                                    ]}
                                />

                            </div>
                        </>}


                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label='Email'
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Email' />
                            <div className="w-full"></div>
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={mobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={phone => setMobile(phone)} />
                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={alternativeMobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                placeholder='Alternative Phone'
                                label='Alternative Phone'
                                onChange={phone => setAlternativeMobile(phone)} />

                        </div>

                        {selectType === USER_TYPE_RELATIVE_ID &&
                            <>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <SelectField
                                        onChange={(e) => setRelationship(e.target.value)}
                                        value={relationship}
                                        label='Relationship to the patient'
                                        required={true}
                                        placeHolder={true}
                                        options={[
                                            { name: 'Select Relationship', id: '' },
                                            ...relationshipData
                                        ]}
                                    />
                                    <div className="w-full"></div>
                                </div>
                                <div className=" title-border-b ">
                                    Patient Information
                                </div>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <div className="flex flex-col gap-9 w-full">
                                        <TextField
                                            label=' First Name '
                                            value={patientFirstName}
                                            onChange={(e) => setPatientFirstName(e.target.value)}
                                            required={selectType === USER_TYPE_RELATIVE_ID}
                                            placeholder=' First Name ' />
                                        <TextField
                                            label=' Middle Name'
                                            value={patientMiddleName}
                                            onChange={(e) => setPatientMiddleName(e.target.value)}
                                            placeholder=' Middle Name' />
                                    </div>
                                    <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                                </div>

                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <TextField
                                        label='  Last Name '
                                        value={patientLastName}
                                        onChange={(e) => setPatientLastName(e.target.value)}
                                        required={selectType === USER_TYPE_RELATIVE_ID}
                                        placeholder='  Last Name ' />
                                    <SelectField
                                        onChange={(e) => setPatientGender(e.target.value)}
                                        value={patientGender}
                                        label='Gender'
                                        placeHolder={true}
                                        options={GENDER_SEL_OPTIONS}
                                    />
                                </div>


                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <TextField
                                        label='DOB '
                                        type='date'
                                        required={true}
                                        value={patientDOB}
                                        onChange={(e) => setPatientDOB(e.target.value)}
                                        placeholder='DOB ' />
                                    <div className="w-full"></div>
                                </div>
                            </>
                        }

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <label className='flex flex-col gap-1 w-full'>

                                <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                    Notes
                                </span>
                                <textarea value={notes} onChange={e => setNotes(e.target.value)} name="" id="" cols={30} rows={3} className='common-input focus:outline-none   resize-none' />
                            </label>
                            <label className='flex flex-col gap-1 w-full'>

                                <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                    Allergies
                                </span>
                                <textarea value={allergies} onChange={e => setAllergies(e.target.value)} name="" id="" cols={30} rows={3} className='common-input focus:outline-none  resize-none' />
                            </label>
                        </div>


                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="w-full">
                                <div className="flex items-center justify-between mb-2">
                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                        Email Notifications
                                    </span>
                                    <div className="flex gap-4">
                                        <input type="radio" id="Yes" name="email_notification" value={EMAIL_NOTIFICATIONS_YES} checked={emailNotification === EMAIL_NOTIFICATIONS_YES} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_YES)} /><label htmlFor="Yes">Yes</label></div>
                                    <div className="flex gap-4">
                                        <input type="radio" id="No" name="email_notification" value={EMAIL_NOTIFICATIONS_NO} checked={emailNotification === EMAIL_NOTIFICATIONS_NO} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_NO)} /><label htmlFor="No">No</label></div>

                                </div>

                            </div>
                            <div className="w-full"> &nbsp;</div>

                        </div>
                    </>}

                    {loginUserRole === USER_ROLE_NURSING_STAFF && <>
                        <div className="flex gap-8 w-full flex-wrap md:flex-nowrap">
                            <div className="flex flex-col gap-9 w-full">
                                <TextField
                                    label=' First Name'
                                    required={true}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder=' First Name' />
                                <TextField
                                    label=' Middle Name'
                                    value={middleName}
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    placeholder=' Middle Name' />
                            </div>
                            <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label=' Last Name'
                                required={true}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder=' Last Name' />


                            <SelectField

                                // onChange={(e) => setFacility(e.target.value)}
                                label='Select Gender'
                                placeHolder={true}
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                options={GENDER_SEL_OPTIONS}
                            />

                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <MultiSelectField
                                // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                onChange={(values) => setMultiFacility(values)}
                                value={multifacility}
                                placeHolder={'Select Facility'}
                                required={true}
                                label='Select Facility'
                                selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                                options={facilityData}
                            />
                            <div className="w-full"> &nbsp;</div>
                        </div>  <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                type='email'
                                disabled={true}
                                label='Email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required={true}
                                placeholder='Email' />
                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={mobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={phone => setMobile(phone)} />
                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="w-full">
                                <div className="flex items-center justify-between mb-2">
                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                        Email Notifications
                                    </span>
                                    <div className="flex gap-4">
                                        <input type="radio" id="Yes" name="email_notification" value={EMAIL_NOTIFICATIONS_YES} checked={emailNotification === EMAIL_NOTIFICATIONS_YES} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_YES)} /><label htmlFor="Yes">Yes</label></div>
                                    <div className="flex gap-4">
                                        <input type="radio" id="No" name="email_notification" value={EMAIL_NOTIFICATIONS_NO} checked={emailNotification === EMAIL_NOTIFICATIONS_NO} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_NO)} /><label htmlFor="No">No</label></div>

                                </div>

                            </div>
                            <div className="w-full"> &nbsp;</div>
                        </div>

                    </>}
                    {loginUserRole === USER_ROLE_PROVIDER && <>
                        <div className="flex gap-8 w-full  flex-wrap md:flex-nowrap">
                            <div className="flex flex-col gap-9 w-full">
                                <TextField
                                    label=' First Name'
                                    required={true}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder=' First Name' />
                                <TextField
                                    label=' Middle Name'
                                    value={middleName}
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    placeholder=' Middle Name' />
                            </div>
                            <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label=' Last Name'
                                required={true}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder=' Last Name' />
                            {/* <div className={` w-full`}>
                                <div className={` max-w-[286px] `}> */}
                            <SelectField
                                // onChange={(e) => setFacility(e.target.value)}
                                label='Select Gender'
                                placeHolder={true}
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                options={GENDER_SEL_OPTIONS}
                            />
                            {/* </div>
                            </div> */}
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <MultiSelectField
                                // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                onChange={(values) => setMultiFacility(values)}
                                value={multifacility}
                                placeHolder={'Select Facility'}
                                required={true}
                                label='Select Facility'
                                selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                                options={facilityData}
                            />
                            <div className="w-full"> &nbsp;</div>
                        </div>  <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                type='email'
                                disabled={true}
                                label='Email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required={true}
                                placeholder='Email' />
                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={mobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={phone => setMobile(phone)} />
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="w-full">
                                <div className="flex items-center justify-between mb-2">
                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                        Email Notifications
                                    </span>
                                    <div className="flex gap-4">
                                        <input type="radio" id="Yes" name="email_notification" value={EMAIL_NOTIFICATIONS_YES} checked={emailNotification === EMAIL_NOTIFICATIONS_YES} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_YES)} /><label htmlFor="Yes">Yes</label></div>
                                    <div className="flex gap-4">
                                        <input type="radio" id="No" name="email_notification" value={EMAIL_NOTIFICATIONS_NO} checked={emailNotification === EMAIL_NOTIFICATIONS_NO} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_NO)} /><label htmlFor="No">No</label></div>

                                </div>

                            </div>
                            <div className="w-full"> &nbsp;</div>
                        </div>


                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <div className="w-full">
                                <span className="text-[15px] text-[var(--color-212121)] tracking-[0.05px]">
                                    Signature (Sign or Upload Image)
                                </span>
                                <div className="flex mt-[18px] flex-col gap-1">
                                    <div className="flex justify-center items-center border border-[var(--color-9E9E9E)] rounded-xl h-[175px]  overflow-hidden object-fit">

                                        {signImage !== '' ? <img src={signImage} alt="" className='h-[175px] w-auto min-h-[100px]' /> : <SignatureCanvas
                                            canvasProps={{ className: 'sigCanvas w-full h-full ' }}
                                            ref={(data: any) => setSign(data)}
                                        />}
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <label htmlFor='signImage' className="relative text-[var(--color-424242)] text-xs tracking-[0.05px] w-fit font-medium cursor-pointer">
                                            Upload Sign Image
                                            <input
                                                name='signImage'
                                                id='signImage'
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                className="hidden"
                                                onChange={(e) => handleAddSign(e)}
                                            />
                                        </label>
                                        <span className='text-right text-[var(--color-424242)] text-xs tracking-[0.05px] font-medium cursor-pointer ' onClick={() => {
                                            handleRemoveSign();
                                        }}>Clear</span></div>
                                </div>
                            </div>
                            <div className='w-full'></div>
                        </div>
                    </>}
                    {loginUserRole === USER_ROLE_CLIENT_ASSOCIATE && <>

                        <div className="flex gap-8 w-full flex-wrap md:flex-nowrap">
                            <div className="flex flex-col gap-9 w-full">
                                <TextField
                                    label=' First Name'
                                    required={true}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder=' First Name' />
                                <TextField
                                    label=' Middle Name'
                                    value={middleName}
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    placeholder=' Middle Name' />
                            </div>
                            <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label=' Last Name'
                                required={true}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder=' Last Name' />
                            <SelectField
                                label='Select Gender'
                                placeHolder={true}
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                options={GENDER_SEL_OPTIONS}
                            />
                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <MultiSelectField
                                onChange={(values) => setMultiFacility(values)}
                                value={multifacility}
                                disabled={true}
                                placeHolder={'Select Facility'}
                                required={true}
                                label='Select Facility'
                                selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                                options={facilityData}
                            />
                            <div className="w-full"></div>

                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                type='email'
                                disabled={true}
                                label='Email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required={true}
                                placeholder='Email' />
                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={mobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={phone => setMobile(phone)} />


                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <label className='flex flex-col gap-1 w-full'>

                                <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                    Notes
                                </span>
                                <textarea value={notes} onChange={e => setNotes(e.target.value)} name="" id="" cols={30} rows={6} className='common-input focus:outline-none   resize-none' />
                            </label>
                            <div className='w-full'></div>
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="w-full">
                                <div className="flex items-center justify-between mb-2">
                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                        Email Notifications
                                    </span>
                                    <div className="flex gap-4">
                                        <input type="radio" id="Yes" name="email_notification" value={EMAIL_NOTIFICATIONS_YES} checked={emailNotification === EMAIL_NOTIFICATIONS_YES} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_YES)} /><label htmlFor="Yes">Yes</label></div>
                                    <div className="flex gap-4">
                                        <input type="radio" id="No" name="email_notification" value={EMAIL_NOTIFICATIONS_NO} checked={emailNotification === EMAIL_NOTIFICATIONS_NO} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_NO)} /><label htmlFor="No">No</label></div>

                                </div>
                                {/* #### Later Use #### */}
                                {/* <div className="flex items-center justify-between">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            SMS Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="sms_notification" value="Yes" /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="sms_notification" value="No" /><label htmlFor="No">No</label></div>

                                    </div> */}

                            </div>
                            <div className="w-full"> &nbsp;</div>

                        </div>
                    </>}
                    {loginUserRole === USER_ROLE_CLIENT_ADMIN && 
                    <>

                        <div className="flex gap-8 w-full flex-wrap md:flex-nowrap">
                            <div className="flex flex-col gap-9 w-full">
                                <TextField
                                    label=' Client Name'
                                    required={true}
                                    value={clientName}
                                    onChange={e => setClientName(e.target.value)}
                                    placeholder=' Client Name' />
                                <TextField
                                    label=' Street 1'
                                    required={true}
                                    value={streetOne}
                                    onChange={e => setStreetOne(e.target.value)}
                                    placeholder=' Street 1' />
                            </div>
                            <ImageInput
                                label='Logo'
                                squareShape={true}
                                profileImage={logoImage}
                                handleAddImage={handleAddImage}
                                handleRemoveImage={handleRemoveImage} />

                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label=' Street 2'
                                value={streetTwo}
                                onChange={e => setStreetTwo(e.target.value)}
                                placeholder=' Street 2' />
                            <TextField
                                label=' City'
                                required={true}
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                placeholder=' City' />

                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <SelectField
                                search={true}
                                // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                onChange={(e) => setState(e.target.value)}
                                name='state'
                                id='state'
                                label='State'
                                value={state}
                                required={true}
                                placeHolder={true}
                                options={stateData}
                            />

                            <TextField
                                label='ZIP Code'
                                type='text'
                                required={true}
                                value={zipCode}
                                onChange={e => setZipCode(filterDigitsOnly(e.target.value))}
                                placeholder='ZIP Code'
                            />

                        </div>


                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={mobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={phone => setMobile(phone)} />
                            <div className="w-full"></div>

                        </div>

                        <div className=" title-border-b ">
                            Primary User Information
                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="flex flex-col gap-9 w-full">
                                <TextField
                                    label='First Name'
                                    required={true}
                                    value={primaryContactFirstName}
                                    onChange={e => setPrimaryContactFirstName(e.target.value)}
                                    placeholder='First Name' />
                                <TextField
                                    label='Middle Name'
                                    value={primaryContactMiddleName}
                                    onChange={e => setPrimaryContactMiddleName(e.target.value)}
                                    placeholder='Middle Name' />
                            </div>
                            <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddProfileImage} handleRemoveImage={handleRemoveProfielImage} />

                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label='Last Name'
                                required={true}
                                value={primaryContactLastName}
                                onChange={e => setPrimaryContactLastName(e.target.value)}
                                placeholder='Last Name' />
                            <div className="w-full"></div>
                        </div>

                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <PhoneInputFeild
                                inputProps={{
                                    name: `primaryMobile`,
                                    autoFocus: false,
                                }}
                                value={primaryMobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={phone => setPrimaryMobile(phone)} />
                            <TextField
                                type='email'
                                disabled={true}
                                label='Email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required={true}
                                placeholder='Email' />
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="w-full">
                                <div className="flex items-center justify-between mb-2">
                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                        Email Notifications
                                    </span>
                                    <div className="flex gap-4">
                                        <input type="radio" id="Yes" name="email_notification" value={EMAIL_NOTIFICATIONS_YES} checked={emailNotification === EMAIL_NOTIFICATIONS_YES} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_YES)} /><label htmlFor="Yes">Yes</label></div>
                                    <div className="flex gap-4">
                                        <input type="radio" id="No" name="email_notification" value={EMAIL_NOTIFICATIONS_NO} checked={emailNotification === EMAIL_NOTIFICATIONS_NO} onClick={() => setEmailNotification(EMAIL_NOTIFICATIONS_NO)} /><label htmlFor="No">No</label></div>

                                </div>

                            </div>
                            <div className="w-full"> &nbsp;</div>

                        </div>

                        {/* <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">

                            <div className="w-full">
                                <span className="text-[15px] text-[var(--color-212121)] tracking-[0.05px]">
                                    {loginUserRole === USER_ROLE_CLIENT_ADMIN ? <>Signature (Sign or Upload Image)</> : <>Signature (Upload Image)</>}
                                </span>
                                <div className="flex mt-[18px] flex-col gap-1">
                                    <div className="flex justify-center items-center border border-[var(--color-9E9E9E)] rounded-xl h-[175px]  overflow-hidden object-fit">

                                        {(signImage !== '') ? <img src={signImage} alt="" className='h-[175px] w-auto min-h-[100px]' /> : loginUserRole === USER_ROLE_CLIENT_ADMIN ? <SignatureCanvas
                                            canvasProps={{ className: 'sigCanvas w-full h-full ' }}
                                            ref={(data: any) => setSign(data)}
                                        /> : <></>}
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <label htmlFor='signImage' className="relative text-[var(--color-424242)] text-xs tracking-[0.05px] w-fit font-medium cursor-pointer">
                                            Upload Sign Image
                                            <input
                                                name='signImage'
                                                id='signImage'
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                className="hidden"
                                                onChange={(e) => handleAddSign(e)}
                                            />
                                        </label>
                                        <span className='text-right text-[var(--color-424242)] text-xs tracking-[0.05px] font-medium cursor-pointer ' onClick={() => {
                                            handleRemoveSign();
                                        }}>Clear</span></div>
                                </div>
                            </div>
                            <div className='w-full'></div>
                        </div> */}

                    </>}

                    <hr className='border-[var(--color-E0E0E0)] mt-9' />
                    <div className="flex gap-4 justify-end items-end my-9">
                        <ButtonType
                            type='webCardbtn'
                            buttonLabel='Reset'
                            onClick={() => handleResetProfile()}
                            className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                        <ButtonType
                            type='webCardbtn'
                            buttonLabel='Save'
                            buttonType='submit'
                            // onClick={() => setAlertOpen(true)}
                            className='px-12 bg-[var(--color-0048D4)]' />
                    </div>
                </div>
            </form>

        </div>
    )
}

export default MyProfile;